// Import mixins
@import 'components/mixins.scss';

.card {
  // border-radius: 1rem;
  border-radius: 9px;
}

.border-radius-18 {
  // border-radius: 1rem;
  border-radius: 12px;
}

.border-top-radius-none {
  // border-radius: 1rem;
  border-top-radius: 0px;
}

.ant-card-table > .ant-card-body {
  padding: rem(4.5) rem(5.4) rem(4.5) rem(4.5) !important;
}

.ant-card-hover > .ant-card-body {
  padding: rem(7.2) rem(7.2) !important;
}

.ant-card-document > .ant-card-body {
  padding: rem(10.8) rem(14.4) !important;
}

.ant-card-xsmall > .ant-card-body {
  padding: rem(5.4) rem(12) rem(5.4) rem(12) !important;
}

.ant-card-visibility > .ant-card-body {
  padding: rem(10) rem(18) rem(10) rem(18) !important;
}

.ant-card-small > .ant-card-body {
  padding: rem(9) rem(16) rem(9) rem(16) !important;
}

.ant-card-medium > .ant-card-body {
  padding: rem(16) rem(21) rem(16) rem(21) !important;
}

.ant-card-analytics > .ant-card-body {
  padding: rem(10.8) rem(21.6) rem(10.8) rem(21.6) !important;
}

.ant-card-form > .ant-card-body {
  padding: rem(4) rem(21) rem(8) rem(21) !important;
}

.ant-card-layout > .ant-card-body {
  padding: rem(10.8) rem(14.4) !important;

  @media (max-width: $sm-max-width) {
    padding: rem(0) !important;
  }
}

// .ant-card-document > .ant-card-body {
//   padding: rem(10.8) rem(14.4) !important;

//   @media (max-width: $sm-max-width) {
//     padding: rem(0) !important;
//   }
// }

.ant-card-drawer > .ant-card-body {
  padding: rem(16) rem(24) rem(16) rem(24) !important;
}

.ant-card-large > .ant-card-body {
  padding: rem(32) rem(27) rem(32) rem(27) !important;
}

.ant-card-body {
  padding: rem(16) rem(16) rem(16) rem(16) !important;
}

.ant-card-meta-title {
  font-size: 14px !important;
}

.ant-card:not(.ant-card-bordered) {
  box-shadow: none;
}

.card-shadow {
  // box-shadow: 2px 4px 12px rgba(0, 0 ,0 , 0.08);
  //box-shadow: 1px 0 6px 0 rgba(0,0,0,.2);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 20px 0px;
}

.card-shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 20px 0px;
}

.card-shadow-3 {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.card-shadow-no-bottom {
  box-shadow: -4px -4px 6px rgba(0, 0, 0, 0.05), 4px -4px 6px rgba(0, 0, 0, 0.05);
}

.card-shadow-4 {
  box-shadow: 3px 3px 3px 2px rgba(17, 17, 26, 0.02), 0px 8px 32px rgba(17, 17, 26, 0.05);
}

.card-shadow-subtle {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
