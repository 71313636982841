:root {
  --primary: #2754ff;
  --primary-light: #3572ff;
  --primary-hover: #2045d1;
  --primary-active: #234ce8;
  --primary-border: #4f78ff;
  --primary-dark: #1936a3;
  --white-strict: #ffffff;
  --black-strict: #000000;

  --sticky-bar: #ffffff;

  --gray-25: #f5f5f7;
  --gray-75: #f1f1f1;
  --gray-50: #f9f9f9;
  --gray-100: #e8e8ed;
  --gray-200: #d9d9d7;
  --gray-300: #bec3c9;
  --gray-400: #8d949e;
  --gray-500: #606060;
  --gray-600: #444950;
  --gray-700: #303338;
  --gray-800: #1c1e21;
  --gray-900: #000000;

  --white: #ffffff;
  --black: #000000;
  --darker: #000000;
  --text: #000000;

  --cash: #e0fcee;
  --tds: #fff8b5;
  --tcs: #ffebf2;
  --upi: #e5e6fc;
  --card: #fbe2fa;
  --netbanking: #fce6e0;
  --paylater: #fee7e9;
  --emi: #f2ffcf;
  --cheque: #e3fafe;

  //Active 5%
  --cash-active: #cafae2;
  --tds-active: #fff69f;
  --tcs-active: #ffd2e2;
  --upi-active: #cfd1fa;
  --card-active: #f8cdf6;
  --netbanking-active: #fad4ca;
  --paylater-active: #fdd0d4;
  --emi-active: #ecffb8;
  --cheque-active: #ccf6fd;

  //Hover 9%
  --cash-hover: #b9f8d9;
  --tds-hover: #fff48e;
  --tcs-hover: #ffbfd5;
  --upi-hover: #bec1f8;
  --card-hover: #f6bcf3;
  --netbanking-hover: #f8c6b9;
  --paylater-hover: #fcbdc3;
  --emi-hover: #e7ffa5;
  --cheque-hover: #b9f3fd;

  // --cash-hover: #{darken($cash, 9%)};
  // --cash-active: #{darken($cash, 5%)};

  --amount: #f3f9ff;
  --paid: #f3faf6;
  --pending: #fdf0e6;

  --cash-text: #042916;
  --upi-text: #05072c;
  --tds-text: #19180b;
  --tcs-text: #ccbcc2;
  --card-text: #290527;
  --netbanking-text: #280c04;
  --paylater-text: #330208;
  --emi-text: #323231;
  --cheque-text: #012931;

  --amount-text: #001933;
  --paid-text: #021f0e;
  --pending-text: #191812;

  --success: #cbf4c9;
  --success-text: #02220d;

  --success-active: #b9f0b7;
  --warning-active: #fff2b6;
  --danger-active: #ffd7e2;

  --success-hover: #abeda8;
  --warning-hover: #ffefa4;
  --danger-hover: #ffc3d3;

  --warning: #fff6cd;
  --warning-text: #331302;
  --danger: #fff0f4;
  --danger-text: #db0629;

  // --add-new: #fd5e76;

  --lblue: #f5faff;
  --lred: #ffefed;
  --lgreen: #ebf5f1;
  --lyellow: #fffbf3;
  --lpurple: #f6f4fb;
  --lpink: #fff1f5;
  --lblue2: #deeeff;

  --positive-bg: #f5faf8;
  --negative-bg: #fef8f8;

  --purple: #453473;
  --orange2: #ffe1d6;
  --orangedark: #672a16;
  --orangeproduct: #ff3819;
  --gray-9: #e3e8ee;
  --orangeStore: #fc3654;

  --card-magenta: #fce6fa;
  --card-azure: #e6f2ff;
  --card-forest: #e7f3ed;
  --card-orange: #fdf0e6;
  --card-white: #fffafb;
  --card-indigo: #efeaff;
  --card-orange-dark: #fdf0e6;

  --view-button: #f2ebff;
  --record-payment: #fff6cd;
  --send-button: #e3faec;

  --view-button-active: #e2d2ff;
  --record-payment-active: #fff2b6;
  --send-button-active: #cff6de;

  --view-button-hover: #d5bfff;
  --record-payment-hover: #ffefa4;
  --send-button-hover: #bff3d3;

  --blue50: #ddfffe;
  --blue100: #cff5f6;
  --blue200: #75d5e8;
  --blue300: #06b9ef;
  --blue400: #0196ed;
  --blue500: #0570de;
  --blue600: #0055bc;
  --blue700: #03438e;
  --blue800: #013260;
  --blue900: #011c3a;

  --green50: #ecfed7;
  --green100: #cbf4c9;
  --green200: #76df47;
  --green300: #48c404;
  --green400: #3ea50b;
  --green500: #228403;
  --green600: #05690d;
  --green700: #0a5118;
  --green800: #043b15;
  --green900: #02220d;

  --g100: #fafefa;
  --g200: #f5fdf4;
  --g300: #effcef;
  --g400: #eafbe9;
  --g500: #e5fae4;

  --orange50: #fff6cd; //fef9da;
  --orange100: #fff2b7; //fcedb9;
  --orange200: #fcbd3a;
  --orange300: #ff8f0e;
  --orange400: #ed6804;
  --orange500: #c84801;
  --orange600: #a82c00;
  --orange700: #842106;
  --orange800: #5f1a05;
  --orange900: #331302;

  --red50: #fff5fa;
  --red100: #ffe7f2;
  --red200: #ffb1cd;
  --red300: #fe87a1;
  --red400: #fc526a;
  --red500: #df1b41;
  --red600: #b3063d;

  --r500: #f8cdd4;
  --r400: #f9d7dd;
  --r300: #fbe1e5;
  --r200: #fcebee;
  --r100: #fef5f6;

  --chat-message-user: #0872f5;
  --chat-message-ai: #eaeaea;
  --chat-text-user: #ffffff;
  --chat-text-ai: #000000;
  --beta-tag-colour: #2754ff;
}

[data-kit-theme='swipedark'] {
  --primary: #4967dc;
  --white-strict: #ffffff;
  --black-strict: #000000;

  --sticky-bar: #212121;

  --gray-900: #e8e8ed;
  --gray-800: #e8e8ed;
  --gray-700: #e8e8ed;
  --gray-600: #e8e8ed;
  --gray-500: #d9d9d7;
  --gray-400: #bec3c9;
  --gray-300: #8d949e;
  --gray-200: #606770;
  --gray-100: #444950;
  --gray-75: #303338;
  --gray-50: #2d2d2e;
  --gray-25: #1d1d1f;

  --white: #2d2d2e;
  --black: #ffffff;
  --darker: #ffffff;
  --text: #e8e8ed;

  --cash: #31403a;
  --upi: #312d50;
  --tds: #473d0f;
  --card: #4e2056;
  --netbanking: #572e25;
  --emi: #383d12;
  --cheque: #303a4f;

  --cash-text: #d8ffff;
  --tds-text: #ffefd8;
  --upi-text: #d8e4ff;
  --card-text: #e7d8ff;
  --netbanking-text: #ffdbd8;
  --emi-text: #dcffd8;
  --cheque-text: #d8e1ff;

  --cash-active: #222b28;
  --upi-active: #24213b;
  --tds-active: #362e0e;
  --card-active: #401c46;
  --netbanking-active: #3f2019;
  --emi-active: #2d3111;
  --cheque-active: #202837;

  --cash-hover: #3c4f48;
  --upi-hover: #413b6d;
  --tds-hover: #53460e;
  --card-hover: #5c2765;
  --netbanking-hover: #67352a;
  --emi-hover: #434914;
  --cheque-hover: #3c4862;

  // --amount: #303a50;
  // --paid: #3a5736;
  // --pending: #48324c;

  // --amount-text: #f0f2f7;
  // --paid-text: #f1f6f0;
  // --pending-text: #f2eff3;

  // --success: #1b3825;
  // --success-text: #b7dcb5;

  // --success-active: #1a3523;
  // --warning-active: #43291a;
  // --danger-active: #54020f;

  // --success-hover: #193322;
  // --warning-hover: #412719;
  // --danger-hover: #50020f;

  // --warning: #472b1b;
  // --warning-text: #e6ddb9;

  // --danger: #580210;
  // --danger-text: #fff0f4;

  // --lblue: #435466;
  // --lred: #664440;
  // --lgreen: #495852;
  // --lyellow: #665a42;
  // --lpurple: #504b5c;
  // --lpink: #66424c;
  // --lblue2: #445566;

  //set1
  --lblue: #192e5d;
  --lred: #4f2c24;
  --lgreen: #254137;
  --lyellow: #4a452c;
  --lpurple: #2d2657;
  --lpink: #46274d;
  --lblue2: #2e4b57;

  //set2
  // --lblue: #293B61;
  // --lred: #523029;
  // --lgreen: #3C4F48;
  // --lyellow: #483E13;
  // --lpurple: #413B6D;
  // --lpink: #5B3D61;
  // --lblue2: #3C4862 ;

  --amount: #001933;
  --paid: #021f0e;
  --pending: #191812;

  --amount-text: #f3f9ff;
  --paid-text: #f3faf6;
  --pending-text: #fef6dc;

  --success: #1b3825;
  --success-text: #b7dcb5;

  --success-active: #1a3523;
  --warning-active: #43291a;
  --danger-active: #54020f;

  --success-hover: #193322;
  --warning-hover: #412719;
  --danger-hover: #50020f;

  --warning: #472b1b;
  --warning-text: #e6ddb9;

  --danger: #580210;
  --danger-text: #fff0f4;

  --lblue: #435466;
  --lred: #664440;
  --lgreen: #495852;
  --lyellow: #665a42;
  --lpurple: #504b5c;
  --lpink: #66424c;
  --lblue2: #445566;

  --purple: #f6f4fb;
  --orange2: #ffe1d6;
  --orangedark: #672a16;
  --orangeproduct: #ff3819;
  --gray-9: #e3e8ee;
  --orangeStore: #fc3654;

  --positive-bg: #353e3a;
  --negative-bg: #423131;

  --card-magenta: #46274d;
  --card-azure: #1a3c5e;
  --card-forest: #353e3a;
  --card-orange: #58351a;
  --card-white: #fffafb;
  --card-indigo: #3f3d4a;
  --card-orange-dark: #403932;

  // --view-button: #3f3c48;
  // --record-payment: #4b401a;
  // --send-button: #2c3d35;

  // --view-button-active: #3c3944;
  // --record-payment-active: #473d19;
  // --send-button-active: #2b3b33;

  // --view-button-hover: #393742;
  // --record-payment-hover: #443a18;
  // --send-button-hover: #283730;

  --amount: #303a50;
  --paid: #3a5736;
  --pending: #48324c;

  --amount-text: #f0f2f7;
  --paid-text: #f1f6f0;
  --pending-text: #f2eff3;

  --success: #55674f;
  --success-text: #dcffd8;
  --success-active: #414f3c;
  --success-hover: #7a9172;

  --warning: #703f38;
  --warning-text: #ffdbd8;
  --warning-active: #5b312c;
  --warning-hover: #a25850;

  --danger: #5b1824;
  --danger-text: #fff0f4;
  --danger-active: #4a1922;
  --danger-hover: #701928;

  --view-button: #544d83;
  --record-payment: #71611a;
  --send-button: #556e65;
  --view-button-active: #413b6d;
  --record-payment-active: #53460e;
  --send-button-active: #3c4f48;
  --view-button-hover: #6e65ad;
  --record-payment-hover: #897520;
  --send-button-hover: #7b9b8f;

  --blue900: #ddfffe;
  --blue800: #cff5f6;
  --blue700: #75d5e8;
  --blue600: #06b9ef;
  --blue500: #0196ed;
  --blue400: #0570de;
  --blue300: #0055bc;
  --blue200: #03438e;
  --blue100: #013260;
  --blue50: #011c3a;

  --green900: #ecfed7;
  --green800: #cbf4c9;
  --green700: #76df47;
  --green600: #48c404;
  --green500: #3ea50b;
  --green400: #228403;
  --green300: #05690d;
  --green200: #0a5118;
  --green100: #043b15;
  --green50: #02220d;

  --g100: #3d493c;
  --g200: #516250;
  --g300: #667a65;
  --g400: #667a65;
  --g500: #7a9279;

  --orange900: #fff6cd; //fef9da;
  --orange800: #fff2b7; //fcedb9;
  --orange700: #fcbd3a;
  --orange600: #ff8f0e;
  --orange500: #ed6804;
  --orange400: #c84801;
  --orange300: #a82c00;
  --orange200: #842106;
  --orange100: #5f1a05;
  --orange50: #331302;

  --red600: #fff5fa;
  --red500: #ffe7f2;
  --red400: #ffb1cd;
  --red300: #fe87a1;
  --red200: #fc526a;
  --red100: #df1b41;
  --red50: #b3063d;

  --r500: #996a7b;
  --r400: #805967;
  --r300: #664752;
  --r200: #4c353d;
  --r100: #332329;

  --chat-message-ai: #262626;
  --chat-text-ai: #ffffff;
  --beta-tag-colour: #ffffff;
}
