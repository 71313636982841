// @font-face {
//   font-family: 'Roboto Mono';
//   font-style: normal;
//   font-display: swap;
//   font-weight: 1 999;
//   src: url(../../../fonts/RobotoMono.ttf) format('ttf');
// }

.barcode {
  //font-family: 'Roboto Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
  //  Arial, monospace !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  // letter-spacing: 1px;
  color: #000;
  width: auto;
  height: auto;
  // margin-left: 0.05in;
  // margin-right: 0.05in;
}

@media print {
  .no-print {
    display: none;
  }

  .barcode {
    //font-family: 'Roboto Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    //  Arial, monospace !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    // letter-spacing: 1px;
    color: #000;
    width: auto;
    height: auto;
    // margin-left: 0.05in;
    // margin-right: 0.05in;
  }

  #printable {
    display: block !important;
  }

}
