// Import mixins
@import 'components/mixins.scss';

.ant-modal {
  &-mask {
    background: $gray-50;
  }
  &-header {
    border-color: transparent;
  }
  &-footer {
    border-color: transparent;
  }
  &-content {
    box-shadow: $shadow-3;
  }
}

.ant-modal-content {
  padding: 0px 10px 9px;
  border-radius: 9px !important;
}

.ant-modal .ant-modal-content {
  padding: 16px 24px;
}

// .ant-modal-body {
//   padding-top: 9px !important;
// }

.ant-modal .ant-modal-header {
  margin-bottom: 16px;
}

.ant-modal-footer {
  // padding: 10px 15px !important;
  border-top: none !important;
  // background-color: white;
}

.ant-modal-title {
  font-size: 18px !important;
  font-weight: 700 !important;
}

// [data-kit-theme='default'] .ant-modal-confirm-body {
//   padding-top: 23px !important;
// }

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-modal {
    &-mask {
      background: rgba($black, 0.84);
    }
    &-close-x {
      color: $gray-500;

      &:hover {
        color: $gray-300;
      }
    }
    &-content {
      background: #131020;
    }
    &-header {
      background: #131020;
      border-color: #131020;
    }
    &-footer {
      border-color: #131020;
    }
  }
}

.ant-modal .ant-modal-close {
  width: 32px;
  height: 32px;
  top: 16px;
  right: 14px;
  color: $gray-500;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  z-index: 10;
}

.ant-modal .ant-modal-close:hover {
  color: $gray-900;
  background-color: rgba(0, 0, 0, 0.06);
}

.ant-modal .ant-modal-close::before {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.06);
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 0;
}

.ant-modal .ant-modal-close:hover::before {
  opacity: 1;
}

.ant-modal .ant-modal-close .anticon {
  position: relative;
  z-index: 1;
}

.promotional-modal {
  .ant-modal {
    &-mask {
      background: $gray-50;
      padding: 0 !important;
    }
    &-header {
      border-color: transparent;
    }
    &-footer {
      border-color: transparent;
    }
    &-content {
      box-shadow: $shadow-3;
      padding: 0 !important;
    }
    &-close {
      color: $gray-400;
      position: absolute;
      // background: $gray-400;
      right: 16px;
      top: 16px;
      border-radius: 50%;
      width: 22px;
      height: 22px;

      &:hover {
        color: $gray-50;
        background: $gray-400;
      }
    }
    // &-close-x {
    //   position: absolute;
    //   right: 0px;
    //   top: -14.5px;
    //   width: 24px;
    //   height: 24px;
    // }
    // &-anticon {
    //   color: #a1a1c2;
    //   padding: 13.5px;
    // }
  }

  .ant-modal-content {
    // padding: 0px 4.5px 9px;
    padding: 0 !important;
    border-radius: 9px !important;
  }

  .ant-modal-body {
    // padding-top: none !important;
    padding: 0 !important;
  }

  .ant-modal-header {
    border-bottom: none !important;
    border-radius: 9px !important;
    padding: 0 !important;
  }

  .ant-modal-footer {
    // padding: 10px 15px !important;
    border-top: none !important;
    padding: 0px 0px !important;
  }

  .ant-modal-title {
    font-size: 18px !important;
    font-weight: 700 !important;
    padding: 0 !important;
  }
}
.promotional-modal-light {
  .ant-modal {
    &-mask {
      background: $gray-50;
      padding: 0 !important;
    }
    &-header {
      border-color: transparent;
    }
    &-footer {
      border-color: transparent;
    }
    &-content {
      box-shadow: $shadow-3;
      padding: 0 !important;
    }
    &-close {
      color: $gray-400;
      position: absolute;
      // background: $gray-400;
      right: 16px;
      top: 16px;
      border-radius: 50%;
      width: 24px;
      height: 24px;

      &:hover {
        color: $gray-50;
        background: $gray-500;
      }
    }
    // &-close-x {
    //   position: absolute;
    //   right: 0px;
    //   top: -14.5px;
    //   width: 24px;
    //   height: 24px;
    // }
    // &-anticon {
    //   color: #a1a1c2;
    //   padding: 13.5px;
    // }
  }

  .ant-modal-content {
    // padding: 0px 4.5px 9px;
    padding: 0 !important;
    border-radius: 18px !important;
  }

  .ant-modal-body {
    // padding-top: none !important;
    padding: 0 !important;
  }

  .ant-modal-header {
    border-bottom: none !important;
    border-radius: 18px !important;
    padding: 0 !important;
  }

  .ant-modal-footer {
    // padding: 10px 15px !important;
    border-top: none !important;
    padding: 0px 0px !important;
  }

  .ant-modal-title {
    font-size: 18px !important;
    font-weight: 700 !important;
    padding: 0 !important;
  }
}

.offer-modal {
  .ant-modal-content {
    background: none !important;
  }
}
