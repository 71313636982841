// Import mixins
@import 'components/mixins.scss';

.ant-layout {
  min-height: 100vh !important;
  min-width: 0;
  // background: $gray-50;
}

:where(.css-dev-only-do-not-override-6sm2zf).ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  /* background: #f5f5f7; */
}

.ant-layout-content {
  margin: rem(18) rem(18);

  @media (max-width: $sm-max-width) {
    margin: rem(3.6);
  }
}

.ant-layout-content {
  &.public-layout-content {
    margin: 0;
  }
}

.ant-layout-header {
  padding: 0 !important;
  line-height: 48px !important;
  height: 48px !important;
  background: transparent;
}

.ant-layout-footer {
  padding: 0 !important;
  background: transparent;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: initial !important;
}

.ant-divider-horizontal {
  margin: 5.4px 0px 9px 0px !important;
}

.ant-back-top {
  margin-right: 1.35rem !important;
  margin-bottom: 3.5rem !important;
}

.ant-message-notice-content {
  border-radius: 5px !important;
  font-weight: 700 !important;
}

//carousel slick dots

[data-kit-theme='default'] .ant-carousel .slick-dots-bottom {
  bottom: -9px !important;
}

[data-kit-theme='default'] .ant-carousel .slick-dots li.slick-active button {
  background: $primary;
}

[data-kit-theme='default'] .ant-carousel .slick-dots li button {
  background: $gray-200;
  opacity: 1;
}
