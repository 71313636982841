// Import mixins
@import 'components/mixins.scss';

.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
  width: 24px;
  min-width: 24px;
  height: 32px;
  line-height: 32px;
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-skeleton {
    opacity: 0.1;
  }
}
