// Import mixins
@import 'components/mixins.scss';

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none !important;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: rem(12) rem(16) !important;
}

.collapse-align-center {
  align-items: center !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
  height: 22px;
  display: flex;
  align-items: center;
  padding-inline-end: 12px;
  margin-inline-start: 4px;
}

.ant-collapse.collapse-small > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
  height: 22px;
  display: flex;
  align-items: center;
  padding-inline-end: 12px;
  margin-inline-start: 4px;
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-collapse {
    background: transparent;
    &-header {
      background: $dark-gray-6;
      color: $dark-gray-1 !important;
    }
  }
}
