// Import mixins
@import 'components/mixins.scss';

.ant-tooltip-inner {
  border-radius: rem(9) !important;
  font-size: 12px;
  padding: rem(36) rem(27);
  font-weight: bold;
  //color: $white-strict;
  // background-color: $white !important;
}
