// Import mixins
@import 'components/mixins.scss';

.ant-tag {
  background: $gray-50;
  color: $black !important;
  border-radius: 8px;
}

// .ant-tag-blue {
//   color: $text !important;
//   background: $gray-50 !important;
//   border-color: $gray-50 !important;
// }

// .tag-large {
//   box-sizing: border-box;
//   // margin-bottom: 9px;
//   padding: 0;
//   background-color: $card-orange;
//   color: $gray-900;
//   font-size: 16px;
//   line-height: 30px;
//   width: auto;
//   height: auto;
//   padding-inline: 8px;
//   white-space: nowrap;
// }

.tag-large .ant-tag-close-icon {
  margin-left: 14px;
  font-size: 16px;
  top: 2px;
  transition: all 0.2s;
}

// .ant-tooltip-inner {
//   border-radius: rem(9) !important;
//   // font-size: rem(12.6);
//   padding: rem(36) rem(27);
//   background-color: $darker !important;
// }
