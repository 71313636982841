// Import mixins
@import 'components/mixins.scss';

[data-kit-theme='swipedark'] {
  .ant-input {
    &-disabled {
      border-color: #424242;
      color: #575757 !important;
    }
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-size: rem(10.6);
}

.ant-input {
  color: $gray-900;
  height: 32px;
  background-color: $white;

  &:hover,
  &:focus,
  &:active {
    // Apply box shadow only if the input does not have the borderless class
    &:not(:where(.css-dev-only-do-not-override-vn508q).ant-input-borderless) {
      box-shadow: 0 0 0 1.8px $lblue2 !important;
    }
  }
}

// .gray-outline .ant-input-affix-wrapper {
//   background-color: #f7f7f9; /* Light gray background */
//   // border: 1px solid #e8e8ed; /* Light gray border */
//   border-radius: 50px; /* Rounded corners */
//   transition: border-color 0.3s ease; /* Smooth border transition */
// }

// .gray-outline .ant-input-affix-wrapper:hover {
//   border-color: #b3b3b3; /* Slightly darker border on hover */
// }

// .gray-outline .ant-input-affix-wrapper:focus-within {
//   border-color: #2754ff; /* Blue border when focused */
//   box-shadow: 0 0 4px rgba(39, 84, 255, 0.4); /* Subtle shadow for focus */
// }

.ant-input-number {
  color: $gray-900;
  // &:hover,
  // &:focus,
  // &:active {
  //   // border-color: none !important;
  //   //box-shadow: 0 0 0 2.7px $lblue2 !important;
  // }
}

.ant-input-affix-wrapper {
  height: 32px;
}

.ant-input-affix-wrapper > input.ant-input {
  height: 30px;
}

.ant-input::placeholder {
  font-weight: 400 !important;
}

textarea.ant-select-selection-search-input::placeholder {
  font-weight: 400;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  height: 30px;
  font-weight: 400;
}

.ant-input-number-group .ant-input-number-group-addon {
  padding: 0 12px 0 12px;
  // padding: 0;
  font-size: 14px;
  color: $gray-400;
  font-weight: bold;
  background: none;
}

.ant-input-number-lg input.ant-input-number-input {
  font-size: rem(18);
  font-weight: 600;
}

.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group .ant-input-number-group-addon:last-child {
  border-left: none !important;
}

.ant-input-number .ant-input-number-input:placeholder-shown {
  font-weight: normal !important;
  // font-size: 14px;
}

.ant-input-lg {
  &:hover,
  &:focus,
  &:active {
    border-color: lighter($blue-light, 80%) !important;
    // border-bottom-width: 2.7px !important;
    box-shadow: 0 0 0 2.7px lighten($blue-light, 27%) !important;
  }
}

// .ant-input-affix-wrapper > input.ant-input {
//   padding: 0 !important;
//   font-weight: bold;
//   font-size: rem(16);
//   // &:hover,
//   // &:focus,
//   // &:active {
//   //   // border-color: none !important;
//   //   // border-radius: 5px !important;
//   //   // box-shadow: 0 0 0 2.7px $lblue2 !important;
//   // }
// }

.ant-input-affix-wrapper .ant-input-prefix {
  margin-inline-end: 12px;
}

.ant-input-number-affix-wrapper .ant-input-number-prefix {
  margin-inline-end: 8px;
}

.ant-picker .ant-picker-input > input {
  font-weight: 500;
}

.ant-form-item {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.ant-input-lg {
  padding: 8.5px 11px !important;
  font-size: 16px;
  // border-radius: 5px !important;
}

.addon-form {
  .ant-input-group-addon {
    position: relative;
    padding: 0 1px;
    color: $gray-900;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #ffffff;
    border: none;
    border-radius: 2px;
    transition: all 0.3s;
  }
}

// .ant-input-affix-wrapper >input.ant-input {
//   font-weight: bold;
// }

// .ant-input-number input.ant-input-number-input {
//   font-weight: bold;
// }

.ant-form-vertical .ant-form-item-label {
  padding: 0 !important;
  margin-bottom: 0.5rem;
}

[data-kit-theme='default'] .ant-input-number-prefix {
  color: $gray-500 !important;
}

.ant-form-item .ant-form-item-label > label {
  color: $gray-500;
  font-weight: bold;
  padding: rem(0) rem(3.6) rem(9);
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-right: 1px !important;
}

.ant-form-item-extra {
  font-size: rem(12) !important;
  color: $gray-400 !important;
  line-height: 1rem !important;
  padding-top: 0.27rem !important;
  padding-left: 0.27rem;
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: rem(11.8) !important;
}

.ant-input-affix-wrapper {
  padding: 0px 11px;
  // .ant-input{
  //   border: solid 1px #00a854 !important;
  // }
}

.ant-input-affix-wrapper > input.ant-input:hover,
.ant-input-affix-wrapper > input.ant-input:focus,
.ant-input-affix-wrapper > input.ant-input:active {
  box-shadow: none !important;
}

.ant-input-affix-wrapper::-moz-placeholder {
  opacity: 1;
}

.ant-input-affix-wrapper::-webkit-input-placeholder {
  color: $gray-200;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  color: $gray-200;
}

.ant-input-affix-wrapper::-ms-input-placeholder {
  color: $gray-200;
}

.ant-input-affix-wrapper::placeholder {
  color: $gray-200;
}

.ant-input-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:hover {
  border-color: none !important;
  //  border-right-width: 1px !important;
}

.ant-input-rtl .ant-input-affix-wrapper:hover {
  // border-right-width: 0;
  // border-left-width: 1px !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  // border-color: none !important;
  // border-right-width: 0px !important;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-input-number {
  // border-radius: 5px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-right-width: 2px !important;
}

.ant-form-item .ant-form-item-explain-error {
  padding-left: rem(4.5);
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  background-color: $white;
}

.ant-input-outlined:hover {
  background-color: $white;
}
