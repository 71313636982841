@import 'components/mixins.scss';

// .ant-table table { font-size: 10px; }

// @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');
// RobotoMono-VariableFont_wght

.table {
  table-layout: auto;
  font-variation-settings: 'wght' 600;
  letter-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
  color: $black;
}

.ticket1 {
  color: $black;
  font-family: $base-thermal-family;
}

.ticket2 {
  color: $black;
  font-family: $base-thermal-family;
}

.ticket3 {
  color: $black;
  font-family: $base-thermal-family;
}

#printable {
  display: none;
}

#printable2 {
  display: none;
}

.borderdashed {
  border-top: 1px solid #8792a2;
}

@media print {
  .no-print {
    display: none;
  }

  @page {
    size: auto; /* auto is the initial value */
    margin: 2mm 0mm 3mm 0mm;
  }

  .ticket1 {
    font-weight: 600 !important;
    text-transform: uppercase;
    letter-spacing: -1px;
    color: #000000 !important;
    width: 110mm;
    max-width: 110mm;
    margin-left: 5mm;
    margin-right: 5mm;
    margin-bottom: 20mm;
    padding-bottom: 20mm;
  }

  .ticket3 {
    font-weight: 500 !important;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    color: #000000 !important;
    width: 60mm;
    max-width: 60mm;
    margin-left: 2mm;
    margin-right: 2mm;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $black-strict;
  }

  .page {
    margin-bottom: 100px;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  .ticket2 {
    font-weight: 600 !important;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #000000 !important;
    padding-top: 20px;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 20px;
    page-break-after: auto;
  }

  .shipping_label_container {
    height: '100%';
  }

  .shipping_label {
    font-size: 36 !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #000000 !important;
    padding-top: 40px;
    padding-left: 54px;
    padding-right: 54px;
    padding-bottom: 40px;
    page-break-after: auto;
  }

  .font-size-16-2 {
    font-size: 5mm !important;
  }

  .font-size-18-2 {
    font-size: 6.3mm !important;
  }

  .font-size-13-2 {
    font-size: 4.05mm !important;
  }

  .print-font-5 {
    font-size: 5mm;
  }

  #printable {
    display: block !important;
  }

  #printable2 {
    display: block !important;
    page-break-after: avoid;
  }

  .print-font-2 {
    font-size: 3.33mm;
  }

  .print-font-3 {
    font-size: 3.96mm;
  }

  .print-font-45 {
    font-size: 4.5mm;
  }

  .print-font-4 {
    font-size: 4.5mm;
  }

  .print-font-6 {
    font-size: 6mm;
  }

  .print-table-1 {
    font-weight: 500 !important;
    letter-spacing: -1px;
    border-collapse: collapse;
    page-break-inside: auto;
    width: 110mm;
    color: $black-strict;
  }

  .thermal-print-img {
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
  }

  .print-table-2 {
    font-weight: 500 !important;
    font-size: 5mm !important;
    letter-spacing: 0px;
    border-collapse: collapse;
    page-break-after: avoid;
    height: 100%;
    color: $black-strict;
  }

  .print-table-3 {
    font-weight: 500 !important;
    font-size: 5mm !important;
    letter-spacing: 0px;
    border-collapse: collapse;
    page-break-after: avoid;
    width: 60mm;
    color: $black-strict;
  }
}
