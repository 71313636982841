// Import mixins
@import 'components/mixins.scss';

// statistics

.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1.4;
  list-style: none;
  font-family: $base-font-family;
}

.ant-statistic .ant-statistic-title {
  margin-bottom: rem(4);
  color: $gray-900;
  font-size: 14px;
  font-weight: 400;
}

.ant-statistic .ant-statistic-content {
  // color: $gray-500;
  font-size: 16px;
  font-weight: bold;
}

// drawer

.ant-drawer .ant-drawer-title {
  font-weight: 700;
  font-size: rem(22.5);
}

.ant-drawer .ant-drawer-header {
  // padding: 16px 27px !important;
  border-bottom: 1px solid $gray-100;
}

.ant-drawer-body {
  padding: 24px 24px !important;
  background: $gray-25;
}

.ant-drawer .ant-drawer-close:hover {
  background-color: transparent;
  text-decoration: none;
}

.ant-drawer .ant-drawer-close {
  padding-left: 0 !important;
  padding-top: 2.7px !important;
}

.ant-drawer .ant-drawer-footer {
  padding: 9px 16px;
}

.drawer-header {
  display: flex;
  align-items: center;
}

// alerts

.ant-alert-with-description {
  padding-inline: 12px;
  padding-block: 8px;
}

.ant-alert-with-description .ant-alert-message {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 2px;
}

.ant-alert-with-description .ant-alert-description {
  color: $gray-900;
}

.ant-alert-with-description .ant-alert-icon {
  margin-inline-end: 12px;
  font-size: 18px;
  line-height: 0;
  margin-top: 2px;
}

.custom-alert .ant-alert-icon {
  position: absolute;
  font-size: 18px;
  top: 12px; /* Adjust as needed */
  left: 18px; /* Adjust as needed */
}

.custom-alert .ant-alert-message,
.custom-alert .ant-alert-description {
  margin-left: 48px; /* Adjust to provide space for the icon */
}

// avatar

.avatar-container {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: $gray-200; /* Gradient effect */
  padding: 0.1px; /* This will be the border thickness */
  box-sizing: border-box;
}

.avatar-tick {
  position: absolute;
  bottom: -5px; /* Adjust as needed */
  right: -5px; /* Adjust as needed */
  font-size: 15px; /* Adjust as needed to make the tick smaller */
  color: green;
  background-color: white; /* Optional: if you need a background color for the tick */
  border-radius: 50%; /* Optional: if you need to make the background color circular */
  border: 2px solid white; /* Optional: to add a border around the tick */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1); /* Optional: to add a slight shadow */
}

.ant-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important; /* Overrides any other styles to ensure a circular shape */
  border: none;
}

.ant-avatar {
  min-width: 30px;
  min-height: 30px;
  font-size: 12px;
  line-height: 30px;
}

.ant-avatar-sm {
  min-width: 27px;
  min-height: 27px;
  line-height: 27px;
  font-size: 12px;
}

.ant-avatar-lg {
  min-width: 36px;
  min-height: 36px;
  font-weight: 700;
  font-size: 14px;
  line-height: 44px;
}

//list

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  margin: 0px;
  color: #1d1d1f;
  font-size: 13.5px;
  line-height: 1.5925925925925926;
}

// badge
.ant-badge .ant-badge-count-sm {
  font-size: 9px;
  color: $white-strict;
  font-weight: bold;
}

.ant-badge .ant-badge-count,
.ant-badge .ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  transform: translate(60%, -60%);
}
