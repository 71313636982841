// Import mixins
@import 'components/mixins.scss';

.ant-table {
  color: $gray-900 !important;
  // line-height: 1.35 !important;
  // border-radius: rem(9.9) !important;
  margin-bottom: rem(9) !important;
  // border: 1px solid $gray-100; //#e4e9f0;
  // border-left: none;
  // border-right: none;
}

.ant-table-wrapper .ant-table {
  border-radius: 8px 8px 8px 8px;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background: $gray-50;
  border-bottom: 1px solid $gray-25;
  transition: background 0.2s ease;
}

.ant-table-thead > tr > th {
  color: $gray-500 !important;
  font-weight: medium !important;
  text-align: left;
  background: $gray-50;
  font-size: rem(13) !important;
  border-bottom: 1px solid $gray-75;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

// .ant-table-tbody > tr:last-child > td {
//     padding-bottom: rem(9) !important;
//   // border-bottom: none !important;
// }

.ant-table.ant-table-small .ant-table-tbody > tr > td:first-child,
.ant-table.ant-table-small .ant-table-thead > tr > th:first-child {
  padding-left: 0.72rem !important;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td:last-child,
.ant-table.ant-table-small .ant-table-thead > tr > th:last-child {
  padding-right: 0.72rem !important;
}

// .ant-table-thead th.ant-table-column-sort {
//   border-top-left-radius: rem(9.9) !important;
//   background-color: $white !important;
// }

// .ant-table-column-sorters:hover {
//   background-color: #000 !important;
//   border-top-left-radius: rem(9.9) !important;
// }

.ant-table-thead th.ant-table-column-sort {
  // background: none !important;
  color: $primary !important;
  font-weight: bold !important;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  // background: none !important;
  color: $gray-900 !important;
}

// .ant-table-cell-fix-left {
//   // background: $white !important;
//   border-top-left-radius: rem(9.9) !important;
// }

// .ant-table-wrapper .ant-table-filter-trigger {
//     font-size: 12px;
// }

// .ant-table-thead th.ant-table-column-has-sorters:hover::before {
//   background-color: #000 !important;
// }

// .ant-table-thead th.ant-table-column-has-sorters:hover th.ant-table-column-has-sorters{
//   background: rgb(0, 0, 0) !important;
//   border-top-left-radius: rem(9.9) !important;
// }

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px !important;
}

.ant-table-tbody .ant-table-measure-row {
  visibility: collapse !important;
}

// .ant-table.ant-table-small {
//     font-size: rem(14) !important;

// }

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 24px 9px !important;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 13.5px 5.4px !important;
}

.ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 7.2px 5.4px !important;
}

.ant-table.ant-table-ledger .ant-table-title,
.ant-table.ant-table-ledger .ant-table-footer,
.ant-table.ant-table-ledger .ant-table-tbody > tr > td,
.ant-table.ant-table-ledger .ant-table-thead > tr > th,
.ant-table.ant-table-ledger tfoot > tr > th,
.ant-table.ant-table-ledger tfoot > tr > td {
  padding: 5.4px 5.4px !important;
}

.products-table > .ant-table-title,
.products-table > .ant-table-footer,
.products-table > .ant-table-tbody > tr > td,
.products-table > .ant-table-thead > tr > th,
.products-table > tfoot > tr > th,
.products-table > tfoot > tr > td {
  padding: 27px 9px !important;
}

.ant-table-wrapper .ant-table-footer {
  padding: 4px 4px;
  color: #1d1d1f;
  background: $gray-50;
}

// .ant-table.ant-table-small .ant-table-thead {
//   border: 2px solid #000000 !important;
// }

.ant-table-pagination.ant-pagination {
  margin: 5px !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  margin-inline-end: 4px;
  line-height: 22px;
}

// .ant-table-tbody > tr.ant-table-row:hover > td {
//   background: #fafaff !important;
// }
.ant-table-cell-row-hover {
  background: $gray-50;
}

.ant-pagination-item-active {
  background: $primary !important; //$primary-light !important;
  color: $white !important;
}

.ant-pagination-item-active a {
  color: $white !important;
}

.ant-table-tbody-edit {
  background: $warning !important;
}

.ant-table-tbody-add {
  background: $success !important;
}

.ant-table-tbody-delete {
  background: $danger !important;
}

tr.drop-over-downward td {
  border-bottom: 1.8px solid #fc3654 !important;
}

tr.drop-over-upward td {
  border-top: 1.8px solid #fc3654 !important;
}

tr .ant-table-measure-row {
  visibility: collapse !important ;
}

.ant-table-wrapper tr.ant-table-expanded-row > th,
:where(.css-dev-only-do-not-override-zw0gwg).ant-table-wrapper tr.ant-table-expanded-row:hover > th,
:where(.css-dev-only-do-not-override-zw0gwg).ant-table-wrapper tr.ant-table-expanded-row > td,
:where(.css-dev-only-do-not-override-zw0gwg).ant-table-wrapper
  tr.ant-table-expanded-row:hover
  > td {
  background: $gray-100;
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: none !important;
}

//hover on table

.ant-table-wrapper .ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 0px;
  transform: translateX(-100%);
  transition: none;
  pointer-events: none;
}

.hover-onshow {
  background-color: transparent;
  position: absolute;
  right: 30px;
  top: auto;
}

.hover-onshow-card {
  background-color: $gray-75;
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 9px;
  width: auto;
  //min-width: 100px; /* Adjust as needed */
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.products-table tr.ant-table-expanded-row > td,
.products-table tr.ant-table-expanded-row:hover > td {
  background: transparent !important;
}

.hover-table-actions {
  .ant-table.ant-table-small .ant-table-footer {
    padding: 0 !important;
  }

  td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
    transition: transform 0.3s ease-in-out;
    transform: scale(1);
    padding-right: 10px;
    box-shadow: none !important;
  }

  tr.ant-table-row.ant-table-row-level-0:hover
    td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
    transform: scale(1);
  }

  .ant-table-cell.ant-table-cell-fix-right::after {
    box-shadow: none !important;
  }
}

.payments-table .ant-table-thead > tr > th {
  @apply bg-card-forest text-white; /* Tailwind classes for header */
}

.payments-table .ant-table-tbody > tr:nth-child(odd) {
  @apply bg-card-forest; /* Tailwind class for odd rows */
}

.payments-table .ant-table-tbody > tr:nth-child(even) {
  @apply bg-card-forest; /* Tailwind class for even rows */
}

.payments-table .ant-table-tbody > tr:hover {
  @apply bg-card-forest; /* Light blue background on hover */
}

.payments-table .ant-table-tbody > tr.ant-table-row-selected {
  @apply bg-card-forest; /* Light blue background when active/selected */
}

.payments-table .ant-table-small .ant-table-tbody > tr > td {
  padding-top: 12px !important;
  padding-bottom: 32px !important; /* Adjusted padding values */
}

.payments-table .ant-table-small .ant-table-tbody > .ant-table-row > .ant-table-cell-row-hover {
  @apply bg-card-forest;
}

.payments-table .ant-table-footer {
  @apply bg-card-forest; /* Tailwind classes for footer */
}

@keyframes changeColor {
  from {
    background-color: $lblue; //#f6faff;
  }
}

.new-row {
  animation: changeColor 4s ease, step-start, cubic-bezier(0.42, 0, 1, 1);
}

@keyframes newDocChangeColor {
  from {
    background-color: $lgreen;
  }
}

.ant-table .new-document-created td {
  animation: newDocChangeColor 4s ease, step-start, cubic-bezier(0.42, 0, 1, 1);
}

.item-header-row {
  .ant-table-cell {
    padding: 0 12px !important;
    // height: 5px;
  }
}

// .ant-table-expanded-row .ant-table-cell {
//   padding-left: 0 !important;
//   padding-right: 0 !important;
// }
/* Hide scrollbar for Chrome, Safari, and Opera */
.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge */
.custom-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Additional styles to ensure the table scrolls properly */
.custom-scrollbar {
  overflow-y: auto;
  max-height: 100%; /* Match this to the scroll.y value in your Table component */
}

.custom-table .ant-table-body {
  /* Ensure the table body scrolls properly */
  overflow-y: auto;
  max-height: 100%;
}

/* Optional: to hide the scrollbar on the table body specifically */
.custom-table .ant-table-body::-webkit-scrollbar {
  display: none;
}

.custom-table .ant-table-body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.missing-hsn {
  background-color: #fef8f8;
}

.blink {
  animation: blink-animation 0.5s alternate infinite;
}

@keyframes blink-animation {
  from {
    background-color: #fef8f8;
  }
  to {
    background-color: #ffcccc;
  }
}

.blink_border {
  animation: blink-border 0.5s alternate infinite;
}

@keyframes blink-border {
  from {
    border: 1px solid #ffffff;
  }
  to {
    border: 1px solid #ff3a3a;
  }
}
