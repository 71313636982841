@import 'components/mixins.scss';

.toggleButton {
  font-size: 24px;
  // height: rem(35);
  padding: rem(12) rem(16);
  background: #25d366;
  border: none;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1) !important;
  font-weight: bold;
  border-radius: 50%;
  color: $white;
  transition: all 0.5s ease-in-out;

  &:hover {
    background: #075e54;
    color: $white;
    transform: scale(0.9);
  }

  span {
    font-size: 15px;
  }
}

.chat {
  position: fixed;
  z-index: 998;
  bottom: rem(14);
  right: rem(20);
  @include transition-slow();
}

// dark theme
[data-kit-theme='dark'] {
  .container {
    background: $dark-gray-4;

    &::before {
      border-top: 7px solid $dark-gray-4;
    }
  }
  .toggleButton {
    background: $dark-gray-4;
  }
}
