// Import mixins
@import 'components/mixins.scss';

.ant-dropdown {
  color: $gray-700;
}

.ant-dropdown-menu {
  border-radius: rem(9) !important;
  padding: rem(0) rem(0) !important;
  max-height: 640px;
  overflow: auto;
}

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  margin-top: 4.5px;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,.2);
}

.ant-dropdown-menu-item-group-list {
  margin: 0;
  list-style: none;
  padding: 0;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #fc3654;
  border-radius: rem(8.1);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 5.4px 9px;
  color: $gray-900;
  font-weight: 400;
  font-size: 13px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item, .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
    padding: 5.4px 9px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-divider,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-divider,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 0px 0;
  overflow: hidden;
  line-height: 0;
  background-color: rgba(5, 5, 5, 0.06);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  border-radius: 0px;
}

:where(.css-dev-only-do-not-override-mc75oq).ant-drawer .ant-drawer-close {
  color: $gray-900;
}

.ant-dropdown-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    background: $white;
    box-shadow: 0 0 9px rgba(0,0,0,0.1);
    transform-origin: 0 0;
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-dropdown-menu-item-group-title {
    color: darken($dark-gray-3, 8%) !important;
  }
}
