// Import mixins
@import 'components/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-empty {
    svg * {
      fill: gray-500;
      stroke: $gray-600;
    }
  }
}
