// Import mixins
@import 'components/mixins.scss';

.ant-btn {
  gap: 0px;
  transition: transform 0.3s ease-in-out;
}

.ant-btn.ant-btn-sm {
  font-size: rem(12) !important;
  height: 25px !important;
  padding: 0px 7px;
  border-radius: 6px;
}

.header-add-button {
  font-size: rem(14) !important;
  height: 18px !important;
  padding: 0px 7px;
  border-radius: 6px;
}

.ant-btn.ant-btn-round.ant-btn {
  border-radius: 32px;
  padding-inline-start: 8px;
  padding-inline-end: 8px;
}

//floating add button
.xsmall {
  font-size: rem(11) !important;
  height: 21px !important;
  padding: 0px 9px;
  position: absolute !important;
  top: -2.7px;
}

.ant-btn-compact-item:not(.ant-btn-compact-first-item).ant-btn-compact-last-item,
.ant-btn-compact-item:not(.ant-btn-compact-first-item).ant-btn-compact-last-item.ant-btn-sm,
.ant-btn-compact-item:not(.ant-btn-compact-first-item).ant-btn-compact-last-item.ant-btn-lg {
  border-left: 1.08px solid $gray-200 !important;
}

.ant-btn-compact-item.ant-btn-primary:not([disabled])
  + .ant-btn-compact-item.ant-btn-primary:not([disabled]):before {
  position: absolute;
  top: 0px;
  inset-inline-start: -1px;
  display: inline-block;
  width: 1px;
  height: calc(100%);
}

.ant-btn {
  color: $gray-500;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out; // Ensuring smooth scaling and color transition

  &:focus {
    border-color: none;
    color: $gray-900;
  }

  &-primary {
    @apply bg-primary border-none; // Base button styles using Tailwind utilities
    color: $white-strict;
    font-weight: 700 !important;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out; // Transition similar to Spotify button

    &:hover {
      @apply scale-105; // Slight enlargement on hover
      background-color: $primary-hover !important; // Maintain color transition
    }

    &:active {
      @apply scale-95; // Slight shrink when clicked
      background: $primary-hover !important;
      color: $white-strict;
      box-shadow: 0 0 0 2.7px $primary-light !important;
    }

    &:hover:active,
    &:focus,
    &.active {
      color: $white-strict;
    }
  }
  &-secondary {
    background: $gray-75 !important;
    color: $gray-900 !important;
    font-weight: 700 !important;
    &:hover,
    &:active,
    &:focus {
      background: $gray-200 !important;
      box-shadow: 0 0 0 1.8px $gray-200 !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $gray-300 !important;
    }
  }
  &-primary-light {
    background: $blue-light !important;
    // border: 0.5px solid $primary !important;
    font-weight: bold;
    color: $black !important;
    &:hover,
    &:active {
      background: darken($blue-light, 3.6%) !important;
      box-shadow: 0 0 0 1.08px lighten($blue-light, 36%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.08px lighten($blue-light, 27%) !important;
    }
  }
  &-primary-add {
    background: $white-strict; //lighten($blue-light, 36%) !important;
    color: $primary !important;
    border: 1px dashed lighten($blue-light, 36%) !important;
    border: none !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px lighten($blue-light, 27%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px lighten($blue-light, 27%) !important;
    }
  }
  &-info {
    background-color: $gray-50 !important;
    border: none !important;
    color: $gray-900 !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $gray-100 !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $gray-75 !important;
    }
  }
  &-primary-dropdown {
    background: $primary;
    border: none;
    border-right: 1.08px solid $gray-200 !important;
    color: $white-strict;
    font-weight: bold;
    &:hover,
    &:active {
      background: $primary-hover !important;
      color: $white-strict;
      // border-color: $primary !important;
      // box-shadow: 0 0 0 2.7px $primary-light !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      color: $white-strict;
      // box-shadow: 0 0 0 2.7px lighten($primary, 27%) !important;
    }
  }
  &-outline-dropdown {
    border: 1.08px solid $gray-200 !important;
    border: none;
    border-right: 1.08px solid $gray-200 !important;
    color: $gray-500;
    font-weight: bold;
    &:hover,
    &:active {
      border: 1.08px solid $gray-200 !important;
      color: $primary-hover !important;
      //background: $primary-hover !important;
      // border-color: $primary !important;
      // box-shadow: 0 0 0 2.7px $primary-light !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      // box-shadow: 0 0 0 2.7px lighten($primary, 27%) !important;
    }
  }
  &-default {
    background: $white;
    border: 1.08px solid $gray-200 !important;
    color: $black !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px $gray-200 !important;
      background: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $gray-300 !important;
      background: $white;
    }
  }
  &-action {
    background: $primary !important;
    color: $white-strict !important;
    &:hover,
    &:active {
      background: $primary-hover !important;
      // border-color: lighten($action, 0%) !important;
      box-shadow: 0 0 0 2.7px $primary-light !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px $primary-light !important;
    }
  }
  &-action-o {
    border: 1.35px solid $primary !important;
    background: $white;
    color: $gray-900 !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 2.7px lighten($action, 27%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten($action, 24%) !important;
    }
  }
  &-record-payment {
    background: $record-payment !important;
    border: none !important;
    color: $black !important;
    font-weight: 500;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $record-payment-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $record-payment-active !important;
    }
  }
  &-view-button {
    background: $view-button !important ; //#f2ebff !important;
    border: none !important;
    //color: #1b1833 !important;
    color: $black !important;
    font-weight: 500;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $view-button-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $view-button-active !important;
    }
  }
  &-send-button {
    background: $send-button !important;
    // border: 1.08px solid #25D366 !important;
    border: none !important;
    color: $black !important; //#455a64 !important;
    font-weight: 500;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px $send-button-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $send-button-active !important;
    }
  }
  &-input-button {
    background: $white !important;
    border: 1.08px solid $gray-100 !important;
    color: $gray-500 !important;
    font-weight: 500;
    &:hover,
    &:active,
    &:focus {
      border-color: $primary-light !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $primary-light !important;
    }
  }
  &-shortcut-button {
    background: $gray-100 !important;
    // border: 1.08px solid #25D366 !important;
    border: none !important;
    color: $black !important; //#455a64 !important;
    font-weight: 500;
    font-size: 14px !important;
    min-width: 20px;
    max-width: 60px;
    padding-top: 1px;
    height: 14px !important;
  }

  &-add-description {
    font-size: rem(10) !important;
    height: 18px !important;
    background: $white !important;
    padding: 0px 5px;
    margin-top: 3.6px;
    border: 1.08px solid $gray-200 !important;
    color: $gray-500 !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px $gray-200 !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $gray-500 !important;
    }
  }

  &-add-field {
    font-size: rem(10.8) !important;
    height: 18px !important;
    background: none !important;
    padding: 0px 5px;
    border: none !important;
    color: $primary-light !important;
    &:hover,
    &:active,
    &:focus {
      color: $primary-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      color: $primary-active !important;
    }
  }

  &-text {
    border: none !important;
    color: $gray-500 !important;
    &:hover,
    &:active {
      color: $gray-500 !important;
      background: $gray-75 !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      color: $gray-700 !important;
    }
  }

  &-link {
    border: none !important;
    color: $gray-500 !important;
    &:hover,
    &:active {
      color: $gray-900 !important;
      text-decoration: underline;
    }
    &:hover:active,
    &:focus,
    &.active {
      color: $gray-900 !important;
      text-decoration: underline;
    }
  }

  &-link-2 {
    border: none !important;
    color: $gray-700 !important;
    &:hover,
    &:active {
      color: $primary !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      color: $gray-900 !important;
      box-shadow: none;
    }
  }

  &-copy-text {
    font-size: rem(12) !important;
    height: 21px !important;
    background: $white-strict !important;
    padding: 0px 3.6px;
    margin: 0px 3.6px;
    border: 1.08px solid $gray-200 !important;
    color: $gray-400 !important;
    font-weight: 500;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px $gray-100 !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px $gray-200 !important;
    }
  }

  &-check-ledger {
    font-size: rem(10) !important;
    height: 18px !important;
    padding: 0px 3.6px;
    margin-top: 3.6px;
    background: transparent !important;
    border: 1px solid $gray-300 !important;
    color: $black !important;
    &:hover,
    &:active,
    &:focus {
      border-color: $black !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      border-color: $black !important;
    }
  }

  &-pro-plan {
    border: 1.8px solid #ff5733 !important;
    background-color: #ff5733 !important;
    color: white !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten(#ff5733, 36%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten(#ff5733, 27%) !important;
    }
  }

  &-pro-plan-bg {
    border: 1.8px solid #ff5733 !important;
    color: white !important;
    background: #ff5733 !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten(#ff5733, 36%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten(#ff5733, 27%) !important;
    }
  }

  &-jet-plan {
    border: 1.8px solid #c822ce;
    color: #c822ce !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten(#c822ce, 36%) !important;
      color: $white-strict;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten(#c822ce, 27%) !important;
      color: $white-strict;
    }
  }

  &-jet-plan-bg {
    border: 1.8px solid #c822ce;
    color: white !important;
    background: #d34ed7 !important;

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten(#c822ce, 36%) !important;
      color: $white-strict;
    }

    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten(#c822ce, 27%) !important;
      color: $white-strict;
    }
  }

  &-rise-plan {
    border: 1.8px solid #0fa958 !important;
    color: #0fa958 !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten(#0fa958, 36%) !important;
      color: $white-strict;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten(#0fa958, 27%) !important;
      color: $white-strict;
    }
  }

  &-rise-plan-bg {
    border: 1.8px solid #0fa958 !important;
    color: white !important;
    background: #0fa958 !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten(#0fa958, 36%) !important;
      color: $white-strict;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten(#0fa958, 27%) !important;
      color: $white-strict;
    }
  }

  &-biz-plan {
    border: 1.8px solid $gray-700 !important;
    color: $gray-700 !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px $gray-500 !important;
      color: $white-strict;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px $gray-400 !important;
      color: $white-strict;
    }
  }

  &-biz-plan-bg {
    border: 1.8px solid #333333 !important;
    color: white !important;
    background: #333333;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten(#333333, 36%) !important;
      color: $white-strict;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten(#333333, 27%) !important;
      color: $white-strict;
    }
  }

  &-sign-up {
    background: #ffd30e !important;
    border: none !important;
    color: $black-strict !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten(#ffd30e, 36%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten(#ffd30e, 27%) !important;
    }
  }

  // to be deleted and changed
  &-action-3 {
    background: #f4eafc !important;
    // border: 1.08px solid #533FDB !important;
    color: #3a1359 !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 2.7px lighten(#9130de, 36%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten(#9130de, 27%) !important;
    }
  }
  &-disabled {
    background-color: $gray-100;
    color: black;
  }

  &-pay {
    background: lighten($swiggy-green, 0%) !important;
    // border: 2.16px solid $swiggy-green !important;
    color: $white-strict !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 2px lighten($swiggy-green, 36%) !important;
      border-color: lighten($swiggy-green, 0%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2px lighten($swiggy-green, 27%) !important;
    }
  }
  &-pos {
    background: lighten($pos, 0%) !important;
    border: none !important;
    color: $white-strict !important;
    &:hover,
    &:active,
    &:focus {
      background: darken($pos, 18%) !important;
      // border-color: lighten($pos, 0%) !important;
      box-shadow: 0 0 0 2.7px lighten($pos, 27%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten($pos, 18%) !important;
    }
  }
  &-returns {
    background: lighten($returns, 72%) !important;
    border: 1.08px solid $returns !important;
    &:hover,
    &:active,
    &:focus {
      background: darken($returns, 27%) !important;
      border-color: lighten($returns, 0%) !important;
      color: $returns !important;
    }
  }
  &-help {
    background: #25d366 !important;
    border: none !important;
    color: $white-strict !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten($whatsapp, 36%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten($whatsapp, 27%) !important;
    }
  }
  &-whatsapp {
    background: #25d366 !important;
    border: none !important;
    color: $white-strict !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten($whatsapp, 36%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten($whatsapp, 27%) !important;
    }
  }
  &-email {
    background: $action !important;
    border: none !important;
    // border: 1.8px solid $action !important;
    color: $white-strict !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten($action, 31.5%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten($action, 27%) !important;
    }
  }
  &-whatsapp-outline {
    background: lighten($whatsapp, 45%) !important;
    // border: 1.08px solid #25D366 !important;
    border: none !important;
    color: #455a64 !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten($whatsapp, 36%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten($whatsapp, 27%) !important;
    }
  }
  &-sms-outline {
    border: 2.16px solid #8bb956 !important;
    color: #455a64 !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 2.7px lighten(#8bb956, 36%) !important;
    }
    FF3819 &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten(#8bb956, 27%) !important;
    }
  }
  &-email-outline {
    border: 2.16px solid $action !important;
    color: $gray-800 !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 2.7px lighten($action, 31.5%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 2.7px lighten($action, 27%) !important;
    }
  }

  // &-add {
  //   background: lighten($swiggy-green, 100%) !important;
  //   border-color: $swiggy-green !important;
  //   border: 2.16px solid $swiggy-green !important;
  //   color: $gray-700 !important;
  //   &:hover,
  //   &:active,
  //   &:focus {
  //     // background: lighten($swiggy-green, 0%) !important;
  //     // border-color: lighten($swiggy-green, 0%) !important;
  //     box-shadow: 0 0 0 2.7px lighten($swiggy-green, 36%) !important;
  //     // color: $white-strict !important;
  //   }
  //   &:hover:active,
  //   &:focus,
  //   &.active {
  //     box-shadow: 0 0 0 2.7px lighten($swiggy-green, 5%) !important;
  //   }
  // }
  // &-checkout {
  //   border-top-right-radius: 8px !important;
  //   border-top-left-radius: 8px !important;
  //   background: lighten($orangeStore, 2%) !important;
  //   border-color: $orangeStore !important;
  //   border: 2px solid $orangeStore !important;
  //   color: $white-strict !important;
  //   &:hover:active,
  //   &:focus,
  //   &.active {
  //     box-shadow: 0 0 0 2.7px lighten($orangeStore, 9%) !important;
  //   }
  // }
  // &-round {
  //   background: lighten($swiggy-green, 0%) !important;
  //   // border: 2px solid $swiggy-green !important;
  //   color: $white-strict !important;
  //   &:hover,
  //   &:active,
  //   &:focus {
  //     background: darken($swiggy-green, 18%) !important;
  //     border-color: lighten($swiggy-green, 0%) !important;
  //   }
  //   &:hover:active,
  //   &:focus,
  //   &.active {
  //     box-shadow: 0 0 0 2.7px lighten($swiggy-green, 5%) !important;
  //   }
  // }
  &-edit-button {
    background: $orange50 !important;
    border: none !important;
    color: $orange900 !important;
    font-weight: 500;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px $orange200 !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $orange200 !important;
    }
  }
  &-warning {
    background: $warning !important;
    border: none !important;
    color: $warning-text !important;
    font-weight: 500;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px $warning-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $warning-active !important;
    }
  }
  // &-danger {
  //   background: lighten($danger-bg, 0%) !important;
  //   // border: 2px solid $danger !important;
  //   color: $white-strict !important;
  //   text-shadow: none !important;
  //   &:hover,
  //   &:active,
  //   &:focus {
  //     background: $danger-hover;
  //     // box-shadow: 0 0 0 1.8px $danger-hover !important;
  //   }
  //   &:hover:active,
  //   &:focus,
  //   &.active {
  //     background: $danger-hover;
  //     // box-shadow: 0 0 0 1.8px $danger-active !important;
  //   }
  // }
  // danger light
  &-danger {
    background: $danger !important;
    border: none;
    color: $danger-text !important;
    text-shadow: none !important;
    &:hover,
    &:active,
    &:focus {
      background: $danger-hover;
      // box-shadow: 0 0 0 1.8px $danger-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      background: $danger-hover;
      // box-shadow: 0 0 0 1.8px $danger-active !important;
    }
  }
  &-success {
    background: $success !important;
    border: none;
    color: $success-text !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px $success-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $success-active !important;
    }
  }
  &-upgrade {
    background: $card-azure !important;
    border: 2px solid $card-azure !important;
    color: $gray-900 !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px $card-azure !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $card-azure !important;
    }
  }
  &-success-3 {
    background-color: #05a357 !important;
    border: 1.08px solid #05a357 !important;
    color: $white-strict !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px darken(#05a357, 9%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px darken(#05a357, 5%) !important;
    }
  }
  &-danger-3 {
    background-color: #e11900 !important;
    border: 1.08px solid #e11900 !important;
    font-weight: 700 !important;
    color: $white-strict !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px darken(#e11900, 9%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px darken(#e11900, 9%) !important;
    }
  }
  &-partial {
    background: $warning !important;
    border: none !important;
    color: $success-text !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px $warning-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $warning-active !important;
    }
  }
  &-info {
    background-color: $gray-75 !important;
    border: none !important;
    color: $gray-900 !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $gray-100 !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $gray-50 !important;
    }
  }
  &-primary-2 {
    background-color: $primary !important;
    border: 2px solid $primary !important;
    color: $white-strict !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $primary-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $primary-active !important;
    }
  }
  &-amount {
    background-color: $amount !important;
    border: 2px solid $amount !important;
    color: $amount-text !important;
  }
  &-paid {
    background-color: $paid !important;
    border: 2px solid $paid !important;
    color: $paid-text !important;
  }
  &-pending {
    background-color: $pending !important;
    border: 2px solid $pending !important;
    color: $pending-text !important;
  }
  &-red {
    background-color: $danger !important;
    border: 2px solid $danger !important;
    color: $netbanking-text !important;
  }
  &-cash {
    background-color: $cash !important;
    border: 2px solid $cash !important;
    color: $cash-text !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $cash-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $cash-active !important;
    }
  }
  &-tds {
    background-color: $tds !important;
    border: 2px solid $tds !important;
    color: $tds-text !important;

    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $tds-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $tds-active !important;
    }
  }
  &-upi {
    background-color: $upi !important;
    border: 2px solid $upi !important;
    color: $upi-text !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $upi-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $upi-active !important;
    }
  }
  &-card {
    background-color: $card !important;
    border: 2px solid $card !important;
    color: $card-text !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $card-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $card-active !important;
    }
  }
  &-emi {
    background-color: $emi !important;
    border: 2px solid $emi !important;
    color: $emi-text !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $emi-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $emi-active !important;
    }
  }
  &-paylater {
    background-color: $paylater !important;
    border: 2px solid $paylater !important;
    color: $paylater-text !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $paylater-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $paylater-active !important;
    }
  }
  &-cheque {
    background-color: $cheque !important;
    border: 2px solid $cheque !important;
    color: $cheque-text !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $cheque-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $cheque-active !important;
    }
  }
  &-netbanking {
    background-color: $netbanking !important;
    border: 2px solid $netbanking !important;
    color: $netbanking-text !important;
    &:hover,
    &:active {
      box-shadow: 0 0 0 1.8px $netbanking-hover !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px $netbanking-active !important;
    }
  }
  &-yellow-2 {
    background: lighten($yellow-2, 0%) !important;
    border: none !important;
    color: $black-strict !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px darken($yellow-2, 9%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px darken($yellow-2, 5%) !important;
    }
  }
  &-green-2 {
    background: lighten(#d2e8ec, 0%) !important;
    border: none !important;
    color: #0d3841 !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px darken(#d2e8ec, 9%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px darken(#d2e8ec, 5%) !important;
    }
  }
  &-reminder {
    background: lighten(#ffebee, 0%) !important;
    border: none !important;
    color: #0d3841 !important;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 0 1.8px lighten(#fc3654, 27%) !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      box-shadow: 0 0 0 1.8px lighten(#fc3654, 9%) !important;
    }
  }
  &-add-new-1 {
    background: $white-strict; //lighten($blue-light, 36%) !important;
    color: $add-new !important;
    border: 1px dashed $add-new !important;
    border: none !important;
    &:hover,
    &:active,
    &:focus {
      //box-shadow: 0 0 0 1.8px lighten($add-new, 27%) !important;
      transition: all 0.3s ease;
      transform: scale(0.95);
    }
    &:hover:active,
    &:focus,
    &.active {
      //box-shadow: 0 0 0 1.8px lighten($add-new, 27%) !important;
      // transition: all 0.3s ease;
      color: $add-new;
    }
  }
  &-add-new-table {
    background: transparent; //lighten($blue-light, 36%) !important;
    color: $gray-900 !important;
    border: none !important;
    &:hover,
    &:active,
    &:focus {
      background: 0 0 0 1.8px $gray-25 !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      background: 0 0 0 1.8px $gray-25 !important;
      //box-shadow: 0 0 0 1.8px lighten($blue-light, 27%) !important;
    }
  }
  &-savings {
    // background: $success !important;
    border: none !important;
    color: #0e6245 !important;
  }
  &-transparent {
    border: none !important;
    color: $gray-500 !important;
    &:hover,
    &:active {
      color: $gray-900 !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      color: $gray-900 !important;
    }
  }

  &-transparent-500 {
    background: transparent !important;
    border: none !important;
    color: $gray-500 !important;
    &:hover,
    &:active,
    &:focus {
      color: $primary !important;
    }
    &:hover:active,
    &:focus,
    &.active {
      color: $primary !important;
    }
  }

  &-primary,
  &-danger,
  &-danger-3 {
    &[disabled],
    &[disabled]:hover {
      background: $gray-200 !important;
      border-color: $gray-200 !important;
      box-shadow: none !important;
      cursor: not-allowed !important;
    }
  }
}

.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  background: transparent;
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES

[data-kit-theme='swipedark'] {
  .ant-btn {
    &-add-new-1 {
      background-color: transparent;
    }
  }
}

[data-kit-theme='dark'] {
  .ant-btn {
    &-primary {
      &:hover,
      &:active,
      &:focus {
        background: lighten($blue-light, 0%) !important;
        border-color: lighten($blue-light, 0%) !important;
        color: $white-strict;
      }
    }
    // &-danger {
    //   &:hover,
    //   &:active,
    //   &:focus {
    //     background: lighten($danger, 9%);
    //     border-color: lighten($danger, 9%);
    //     color: $white-strict;
    //   }
    // }
    &-primary,
    &-danger {
      &[disabled],
      &[disabled]:hover {
        background: #1d1b2c !important;
        border-color: #232135 !important;
      }
    }
  }
}
