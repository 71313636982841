// Import mixins
@import 'components/mixins.scss';

// .ant-descriptions.ant-descriptions-bordered .ant-descriptions-row {
// 	border-bottom: none;
// }

.ant-descriptions {
  border-radius: 8px;
  background-color: $white;
}

.ant-descriptions.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid $gray-75;
}

.ant-descriptions.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid $gray-75;
}

.ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-label {
  font-weight: 500;
  border-inline-end: none;
  text-align: left;
  color: $gray-500;
  //background-color: $gray-50;
}

// .ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-label {
//     background-color: $gray-50;
// }

.ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-content {
  font-weight: 500;
  // background-color: $white;
}

.custom-description {
  .ant-descriptions-view {
    table {
      tr {
        th.ant-descriptions-item-label {
          width: 30% !important;
        }

        td.ant-descriptions-item-content {
          width: 70% !important;
        }
      }
    }
  }
}

// .ant-descriptions-item-label {
//   width: 30% !important;
// }

// .ant-descriptions-item-content {
//   width: 70% !important;
// }
