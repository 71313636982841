@import 'components/mixins.scss';

.head {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
}

.title {
  color: $gray-600;
  margin-bottom: 0;
  flex-shrink: 1;
  margin-right: rem(15);
}

.time {
  text-transform: uppercase;
  margin-left: auto;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: rem(12);
  color: gray-500;
}

.content {
  margin-bottom: 0;
  color: gray-400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs {
  :global(.ant-tabs-bar) {
    border-bottom: none;
    position: relative;
    padding-left: 25px;
    padding-right: 25px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 3px;
      right: 0;
      height: 1px;
      background-color: gray-200;
    }
  }

  :global(.ant-tabs-nav) {
    :global(.ant-tabs-tab) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 20px;
      padding-top: 20px;
      margin-right: 25px;
    }

    :global(.ant-tabs-tab-active) {
      color: $blue;
    }
  }

  :global(.ant-tabs-ink-bar) {
    height: 7px;
    border-radius: 5px;
    background-color: $blue;
  }

  :global(.ant-tabs-tabpane) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

// dark theme
[data-kit-theme='dark'] {
  .tabs {
    :global(.ant-tabs-bar) {
      &::before {
        background-color: $dark-gray-4;
      }
    }

    :global(.ant-tabs-nav) {
      :global(.ant-tabs-tab) {
        color: $dark-gray-2;
      }
      :global(.ant-tabs-tab-active) {
        color: $blue;
      }
    }
  }
}

@import 'components/mixins.scss';

.dropdown {
  padding-right: 15px;
  position: relative;
  cursor: pointer;

  &::after {
    opacity: 0.5;
    color: gray-500;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -2px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    @include transition-slow();
  }

  &:hover {
    color: $blue;

    &::after {
      color: $gray-600;
    }
  }
}

.icon {
  margin-right: rem(8);
  position: relative;
  bottom: -2px;
  font-size: rem(17);
  color: gray-500;
  @include transition-slow;

  &:hover {
    color: $primary;
  }
}

.avatar {
  background-color: $gray-200 !important;
}

// dark theme
[data-kit-theme='dark'] {
  .avatar {
    background: $dark-gray-4 !important;
  }
}

