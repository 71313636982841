// Import mixins
@import 'components/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-radio-disabled {
    .ant-radio-inner {
      border-color: $dark-gray-4 !important;
    }
  }

  .ant-radio-group-solid {
    .ant-radio {
      &-button {
        &-wrapper {
          color: $text;
          &-checked {
            color: $white;
            &:hover,
            &:active {
              color: $white;
            }
          }
        }
      }
    }
  }
}
.payment-modes .ant-radio-group-outline {
  .ant-radio {
    &-button {
      &-wrapper {
        height: 2.4rem;
        // line-height: 2.4rem;
        font-size: 1rem;
        font-weight: medium !important;
        margin-bottom: 0.54rem !important;
        margin-right: 0.8rem;
        padding: 0px 12px;
        &::before {
          background-color: $gray-100 !important;
          display: none !important;
        }
        &-checked {
          // color: $white !important;
          // font-weight: bold !important;
          font-weight: bold !important;
          padding: 0px 9px 0px 27px;
          &:hover,
          &:active {
            color: $white;
          }
        }
        .ant-radio {
          &-button {
            &-checked {
              &::before {
                position: relative;
                top: 8px;
                left: 8px;
                display: block !important;
                box-sizing: content-box;
                width: 1px;
                height: 100%;
                padding: 1px 0;
                background-color: none;
                transition: background-color 0.3s, all 0.2s;
                font: var(--fa-font-solid);
                content: '\f058';
              }
            }
          }
        }

        &.cash {
          // background-color: $gray-100 !important;
          background-color: $cash;
          border: 0.9px solid $cash !important;
          color: $cash-text !important;
          &:hover,
          &:active {
            background-color: $cash !important;
            box-shadow: 0 0 0 1.8px $cash-hover !important;
            color: $cash-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $cash !important;
            box-shadow: 0 0 0 1.8px $cash-active !important;
            color: $cash-text !important;
          }
        }
        &.upi {
          // background-color: $gray-100;
          background-color: $upi;

          border: 0.9px solid $upi !important;
          color: $upi-text !important;
          &:hover,
          &:active {
            background-color: $upi;
            box-shadow: 0 0 0 1.8px $upi-hover !important;
            color: $upi-text !important;
          }
          // &:hover:active,
          // &:focus,
          // &.active {
          //   background-color: $upi;
          //   box-shadow: 0 0 0 1.8px $upi-active !important;
          //   color: $upi-text !important;
          // }
        }
        &.card-payment {
          // background-color: $gray-100 !important;
          background-color: $card ;

          border: 0.9px solid $card !important;
          color: $card-text !important;
          &:hover,
          &:active {
            background-color: $card !important;
            box-shadow: 0 0 0 1.8px $card-hover !important;
            color: $card-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $card !important;
            box-shadow: 0 0 0 1.8px $card-active !important;
            color: $card-text !important;
          }
        }
        &.emi {
          // background-color: $gray-100 !important;
          background-color: $emi ;

          border: 0.9px solid $emi !important;
          color: $emi-text !important;
          &:hover,
          &:active {
            background-color: $emi !important;
            box-shadow: 0 0 0 1.8px $emi-hover !important;
            color: $emi-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $emi !important;
            box-shadow: 0 0 0 1.8px $emi-active !important;
            color: $emi-text !important;
          }
        }
        &.paylater {
          // background-color: $gray-100 !important;
          background-color: $paylater ;

          border: 0.9px solid $paylater !important;
          color: $paylater-text !important;
          &:hover,
          &:active {
            background-color: $paylater !important;
            box-shadow: 0 0 0 1.8px $paylater-hover !important;
            color: $paylater-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $paylater !important;
            box-shadow: 0 0 0 1.8px $paylater-active !important;
            color: $paylater-text !important;
          }
        }
        &.cheque {
          // background-color: $gray-100 !important;
          background-color: $cheque ;

          border: 0.9px solid $cheque !important;
          color: $cheque-text !important;
          &:hover,
          &:active {
            background-color: $cheque !important;
            box-shadow: 0 0 0 1.8px $cheque-hover !important;
            color: $cheque-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $cheque !important;
            box-shadow: 0 0 0 1.8px $cheque-active !important;
            color: $cheque-text !important;
          }
        }
        &.tds {
          // background-color: $gray-100 !important;
          background-color: $tds ;
          border: 0.9px solid $tds !important;
          color: $tds-text !important;
          // font-style: italic;
          &:hover,
          &:active {
            background-color: $tds !important;
            box-shadow: 0 0 0 1.8px $tds-hover !important;
            color: $tds-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $tds !important;
            box-shadow: 0 0 0 1.8px $tds-active !important;
            color: $tds-text !important;
          }
        }
        &.netbanking {
          background-color: $netbanking;

          border: 0.9px solid $netbanking !important;
          color: $netbanking-text !important;
          &:hover,
          &:active {
            background-color: $netbanking !important;
            box-shadow: 0 0 0 1.8px $netbanking-hover !important;
            color: $netbanking-text !important;
          }
        
        }
        &.tcs{
          background-color: $tcs;
          border: 0.9px solid $tcs !important;
          color: $netbanking-text !important;
          &:hover,
          &:active {
            background-color: $tcs !important;
            box-shadow: 0 0 0 1.8px $tcs-hover !important;
            color: $netbanking-text !important;
          }
        }
        &.btn-amount {
          background-color: $blue-light-1;
          border-color: $blue-light-1;
          color: $blue !important;

          // border: 2px solid $blue-light-1 !important;
          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $cash-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $cash-hover !important;
          }
        }
        &.btn-info {
          background-color: $info;
          // border: 2px solid $info !important;
          border-color: $info;
          color: $blue-light !important;

          &:hover,
          &:active {
            // background-color: lighten($info, 6%);
            // border-color: lighten($info, 6%);
            box-shadow: 0 0 0 1.8px $primary-light !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            // background-color: darken($info, 6%);
            // border-color: darken($info, 6%);
            box-shadow: 0 0 0 1.8px $primary-light !important;
          }
        }
        &btn-success {
          background-color: $success;
          border-color: $success;
          color: #0e6245 !important;

          // border: 2px solid $success !important;

          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $success-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $success-hover !important;
          }
        }
      }
    }
  }
}
.tutorials .ant-radio-group-outline {
  .ant-radio {
    &-button {
      &-wrapper {
        height: 2.4rem;
        font-size: 1rem;
        font-weight: medium !important;
        margin-bottom: 0.54rem !important;
        margin-right: 0.8rem;
        padding: 0px 12px;
        &::before {
          background-color: $gray-100 !important;
          display: none !important;
        }
        &-checked {
          font-weight: bold !important;
          padding: 0px 9px 0px 27px;
          &:hover,
          &:active {
            color: $white;
          }
        }
        .ant-radio {
          &-button {
            &-checked {
              &::before {
                position: relative;
                top: 8px;
                left: 8px;
                display: block !important;
                box-sizing: content-box;
                width: 1px;
                height: 100%;
                padding: 1px 0;
                background-color: none;
                transition: background-color 0.3s, all 0.2s;
                font: var(--fa-font-solid);
                content: '\f058';
              }
            }
          }
        }
        .ant-radio {
          &-button {
            &-checked {
              &::before {
                position: relative;
                top: 8px;
                left: 8px;
                display: block !important;
                box-sizing: content-box;
                width: 1px;
                height: 100%;
                padding: 1px 0;
                background-color: none;
                transition: background-color 0.3s, all 0.2s;
                font: var(--fa-font-solid);
                content: '\f058';
              }
            }
          }
        }

        &.english {
          // background-color: $gray-100 !important;
          background-color: $cash;
          color: $cash-text !important;
          &:hover,
          &:active {
            background-color: $cash !important;
            box-shadow: 0 0 0 1.8px $cash-hover !important;
            color: $cash-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $cash !important;
            box-shadow: 0 0 0 1.8px $cash-active !important;
            color: $cash-text !important;
          }
        }
        &.hindi {
          // background-color: $gray-100;
          background-color: $upi;
          color: $upi-text !important;
          &:hover,
          &:active {
            background-color: $upi;
            box-shadow: 0 0 0 1.8px $upi-hover !important;
            color: $upi-text !important;
          }
        }
        &.telugu {
          // background-color: $gray-100 !important;
          background-color: $card;
          color: $card-text !important;
          &:hover,
          &:active {
            background-color: $card !important;
            box-shadow: 0 0 0 1.8px $card-hover !important;
            color: $card-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $card !important;
            box-shadow: 0 0 0 1.8px $card-active !important;
            color: $card-text !important;
          }
        }
        &.tamil {
          // background-color: $gray-100 !important;
          background-color: $emi;
          color: $emi-text !important;
          &:hover,
          &:active {
            background-color: $emi !important;
            box-shadow: 0 0 0 1.8px $emi-hover !important;
            color: $emi-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $emi !important;
            box-shadow: 0 0 0 1.8px $emi-active !important;
            color: $emi-text !important;
          }
        }
        &.kannada {
          // background-color: $gray-100 !important;
          background-color: $paylater;
          color: $paylater-text !important;
          &:hover,
          &:active {
            background-color: $paylater !important;
            box-shadow: 0 0 0 1.8px $paylater-hover !important;
            color: $paylater-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $paylater !important;
            box-shadow: 0 0 0 1.8px $paylater-active !important;
            color: $paylater-text !important;
          }
        }
        &.gujarati {
          // background-color: $gray-100 !important;
          background-color: $cheque;
          color: $cheque-text !important;
          &:hover,
          &:active {
            background-color: $cheque !important;
            box-shadow: 0 0 0 1.8px $cheque-hover !important;
            color: $cheque-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $cheque !important;
            box-shadow: 0 0 0 1.8px $cheque-active !important;
            color: $cheque-text !important;
          }
        }
        &.bengali {
          // background-color: $gray-100 !important;
          background-color: $tds;
          color: $tds-text !important;
          // font-style: italic;
          &:hover,
          &:active {
            background-color: $tds !important;
            box-shadow: 0 0 0 1.8px $tds-hover !important;
            color: $tds-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $tds !important;
            box-shadow: 0 0 0 1.8px $tds-active !important;
            color: $tds-text !important;
          }
        }
        &.malayalam {
          background-color: $netbanking;
          color: $netbanking-text !important;
          &:hover,
          &:active {
            background-color: $netbanking !important;
            box-shadow: 0 0 0 1.8px $netbanking-hover !important;
            color: $netbanking-text !important;
          }
        }
        &.odia {
          background-color: $cash;
          color: $cash-text !important;
          &:hover,
          &:active {
            background-color: $cash !important;
            box-shadow: 0 0 0 1.8px $cash-hover !important;
            color: $cash-text !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            background-color: $cash !important;
            box-shadow: 0 0 0 1.8px $cash-active !important;
            color: $cash-text !important;
          }
        }
        &.assamese {
          background-color: $upi;
          color: $upi-text !important;
          &:hover,
          &:active {
            background-color: $upi;
            box-shadow: 0 0 0 1.8px $upi-hover !important;
            color: $upi-text !important;
          }
        }
      }
    }
  }
}
.pricing-plans .ant-radio-group-outline {
  .ant-radio {
    &-button {
      &-wrapper {
        height: 1.8rem;
        line-height: 1.6rem;
        font-size: 1rem;
        font-weight: medium !important;
        margin-bottom: 0.54rem !important;
        margin-right: 0.54rem;
        padding: 0px 9px 0px 9px;
        &::before {
          background-color: none !important;
          display: none !important;
        }
        &-checked {
          color: $white !important;
          font-weight: bold !important;
          padding: 0px 9px 0px 27px;
          &:hover,
          &:active {
            color: $white;
          }
        }
        .ant-radio {
          &-button {
            &-checked {
              &::before {
                position: absolute;
                top: 8px;
                left: 8px;
                display: block !important;
                box-sizing: content-box;
                width: 1px;
                height: 100%;
                padding: 1px 0;
                background-color: none;
                transition: background-color 0.3s;
                font: var(--fa-font-solid);
                content: '\f058';
              }
            }
          }
        }

        &.free {
          background-color: $cash !important;
          border: 0.9px solid $cash !important;
          color: $cash-text !important;
          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $cash-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $cash-active !important;
          }
        }
        &.pro {
          // background-color: lighten($upi, 1%) !important;
          background-color: $blue-light-1;
          border: 0.9px solid $upi !important;
          color: $upi-text !important;
          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $upi-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $upi-active !important;
          }
        }
        &.jet {
          background-color: $card !important;
          border: 0.9px solid $card !important;
          color: $card-text !important;
          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $card-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $card-active !important;
          }
        }

        &.btn-amount {
          background-color: $blue-light-1;
          border-color: $blue-light-1;
          color: $blue !important;

          // border: 2px solid $blue-light-1 !important;
          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $cash-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $cash-hover !important;
          }
        }
        &.btn-info {
          background-color: $info;
          // border: 2px solid $info !important;
          border-color: $info;
          color: $blue-light !important;

          &:hover,
          &:active {
            // background-color: lighten($info, 6%);
            // border-color: lighten($info, 6%);
            box-shadow: 0 0 0 1.8px $primary-light !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            // background-color: darken($info, 6%);
            // border-color: darken($info, 6%);
            box-shadow: 0 0 0 1.8px $primary-light !important;
          }
        }
        &btn-success {
          background-color: $success;
          border-color: $success;
          color: #0e6245 !important;

          // border: 2px solid $success !important;

          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $success-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $success-hover !important;
          }
        }
      }
    }
  }
}

.invoice-themes {
  .ant-radio {
    &-button {
      &-wrapper-checked {
        color: $text;
        height: auto !important;
        &::before {
          background-color: none !important;
          display: none !important;
        }
        &-checked {
          color: $white !important;
          // padding: 0px 15px 0px 25px;
          &:hover,
          &:active {
            color: $white;
          }
        }
        span {
          .template-name {
            &::after {
              position: relative;
              display: inline !important;
              box-sizing: content-box;
              width: 1px;
              height: 100%;
              padding: 1px 10px;
              background-color: none;
              transition: background-color 0.3s;
              font: var(--fa-font-solid);
              content: '\f058';
            }
          }
        }

        &.btn-cash {
          background-color: $cash !important;
          border-color: $cash !important;
          color: #10462d !important;
          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $cash-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $cash-active !important;
          }
        }
        &.btn-upi {
          background-color: $upi;
          border-color: $upi;
          color: #2e224c !important;
          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $upi-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $upi-hover !important;
          }
        }
        &.btn-card {
          background-color: $card;
          border-color: $card;
          color: #672a16 !important;
          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $card-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $card-hover !important;
          }
        }
        &.btn-amount {
          background-color: $blue-light-1;
          border-color: $blue-light-1;
          color: $blue !important;

          // border: 2px solid $blue-light-1 !important;
          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $cash-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $cash-hover !important;
          }
        }
        &.btn-info {
          background-color: $info;
          // border: 2px solid $info !important;
          border-color: $info;
          color: $blue-light !important;

          &:hover,
          &:active {
            // background-color: lighten($info, 6%);
            // border-color: lighten($info, 6%);
            box-shadow: 0 0 0 1.8px $primary-light !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            // background-color: darken($info, 6%);
            // border-color: darken($info, 6%);
            box-shadow: 0 0 0 1.8px $primary-light !important;
          }
        }
        &.btn-success {
          background-color: $success;
          border-color: $success;
          color: #0e6245 !important;

          // border: 2px solid $success !important;

          &:hover,
          &:active {
            box-shadow: 0 0 0 1.8px $success-hover !important;
          }
          &:hover:active,
          &:focus,
          &.active {
            box-shadow: 0 0 0 1.8px $success-hover !important;
          }
        }
      }
    }
  }
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: none !important;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: none;
  transition: background-color 0.3s;
  content: '';
}
