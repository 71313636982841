// Import mixins
@import 'components/mixins.scss';

.ant-select-in-form-item {
  width: 100%;
  // height: 32px !important;
}

// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//   height: 32px !important;
// }

// autocomplete height adjust for antd 5.14
.ant-select.ant-select-outlined.ant-select-auto-complete.ant-select-single.ant-select-show-search
  .ant-select-selector {
  height: 32px; /* Or any other height you'd like to set */
  /* Add other styles if necessary */
}

.ant-select-dropdown .ant-select-item {
  border-radius: 0;
}

.ant-select-multiple .ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
  // padding-left: 10px;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $gray-900;
  font-weight: 600;
  background-color: $gray-75;
}

//for multiple tags
.ant-select-multiple .ant-select-selection-placeholder {
  inset-inline-start: 12.5px;
  inset-inline-end: 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 2.7px $lblue2 !important;
  }
}

.ant-picker {
  height: 32px !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 2.7px $lblue2 !important;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: none !important;
  border-right-width: none !important;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
  border-color: none !important;
  border-right-width: none !important;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  //color: #333333 !important;
  //font-weight: 700 !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  font-weight: 500;
}

.select-borderless {
  .ant-select {
    font-size: 14px;
    .ant-select-selector {
      background: transparent;
      border: none !important;
      border-radius: 5px !important;
      &:hover,
      &:focus,
      &:active {
        border: none !important;
        box-shadow: none !important;
      }
    }
    .ant-select-focused {
      border: none !important;
    }
    .ant-select-clear {
      margin-top: -7.5px;
    }
    .ant-select-arrow {
      margin-top: -7px;
      color: $gray-400;
    }
    .ant-input-number-group .ant-input-number-group-addon {
      padding: 0 4px 0 4px !important;
    }
  }
  .ant-select-single.ant-select-sm .ant-select-selector {
    font-size: 13px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 100% !important;
  }
  .ant-select-selection-placeholder {
    font-weight: 400;
    font-size: 14px;
    color: $gray-400;
  }
}

.select-borderless-mini {
  .ant-select {
    font-size: 12px;
    .ant-select-selector {
      background: transparent;
      border: none !important;
      border-radius: 5px !important;
      &:hover,
      &:focus,
      &:active {
        border: none !important;
        box-shadow: none !important;
      }
    }
    .ant-select-focused {
      border: none !important;
    }
    .ant-select-clear {
      margin-top: -7.5px;
    }
    .ant-select-arrow {
      margin-top: -7px;
      color: $gray-400;
    }
  }
  .ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
    background: none;
  }
  .ant-select-single.ant-select-sm .ant-select-selector {
    font-size: 12px;
    color: $gray-500;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 100% !important;
  }
  .ant-select-selection-placeholder {
    font-weight: 400;
    font-size: 12px;
    color: $gray-400;
  }
}

.ant-select-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0px;
  color: #1d1d1f;
  font-size: 13.5px;
  line-height: 1.5925925925925926;
  list-style: none;
  font-family: $base-font-family;
  position: absolute;
  top: -9999px;
  z-index: 1050;
  overflow: hidden;
  font-variant: initial;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
}

.custom-autocomplete-input .ant-input {
  padding-left: 30px;
  /* Adjust the value as needed */
}

.custom-autocomplete-input .ant-input::placeholder {
  color: #bfbfbf;
  /* Optional: Adjust the placeholder color */
}

[data-kit-theme='default'] .multiple-units {
  position: absolute;
  right: 0;
  background-color: none;
  z-index: 9;
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 2.7px !important;
    border: none !important;
    background-color: transparent !important;
    height: 14.4px !important;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow
    .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow
    .ant-select-selection-placeholder {
    // padding-right: 21px !important;
    line-height: 14.4px !important;
    font-weight: normal;
    font-size: 9px !important;
    color: $gray-700 !important;
  }
}

.ant-select .ant-select-clear {
  position: absolute;
  top: 50%;
  inset-inline-start: auto;
  inset-inline-end: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -7px;
  color: $gray-300;
  font-size: 14px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  //background: #ffffff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.2s ease, opacity 0.3s ease;
  text-rendering: auto;
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-select {
    &-multiple {
      .ant-select-selection-item {
        color: $dark-gray-1;
        background: $primary;

        &-remove {
          color: $dark-gray-1;
        }
      }
    }

    &-item-option-active:not(.ant-select-item-option-disabled),
    &-item-option-selected:not(.ant-select-item-option-disabled) {
      color: $dark-gray-1;
      background: $primary;
    }

    // antd v3 styles
    &-selection {
      &--multiple {
        .ant-select-selection__choice {
          color: $dark-gray-1;
          background: $primary;

          &-remove {
            color: $dark-gray-3;
          }
        }
      }
    }

    &-dropdown-menu-item {
      &-selected {
        color: $dark-gray-1;
        background: $primary;
      }
    }

    &-tree-dropdown {
      .ant-select-dropdown-search {
        .ant-select-search__field {
          background: $dark-gray-5;
        }
      }
    }
  }
}
