// // // Import mixins
// @import 'components/mixins.scss';

// .ant-tabs-nav .ant-tabs-tab {
//   font-size: 15.3px !important;
// }

.ant-tabs-nav .ant-tabs-tab {
  padding: 6.3px 4.5px;
  font-weight: 500;
  color: $gray-500;

  &:first-child {
    // line-height: 5px;
    padding-left: 0px;
  }

  // font-size: 15.3px !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  // font-size: 1.08rem !important;
  color: $primary;
  text-shadow: 0 0 0.5px $primary, 0 0 0.5px $primary !important;
  transition: all 0.5s ease-out;
}

.ant-tabs-form > .ant-tabs-nav .ant-tabs-tab {
  padding: 0px 9px 6.3px 4.5px !important;
}

// /* multi tabs css*/

.ant-tabs-card {
  &.ant-tabs-top > .ant-tabs-nav,
  &.ant-tabs-bottom > .ant-tabs-nav,
  &.ant-tabs-top > div > .ant-tabs-nav,
  &.ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }
}

.ant-tabs-card {
  &:where(.css-dev-only-do-not-override-12eape7) {
    &.ant-tabs-top > .ant-tabs-nav::before,
    &.ant-tabs-bottom > .ant-tabs-nav::before,
    &.ant-tabs-top > div > .ant-tabs-nav::before,
    &.ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-bottom: none; // Remove the border
    }
  }
}

.ant-tabs-card {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $gray-900;
    text-shadow: 0 0 0.25px currentColor;
  }
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 4px 8px 4px 16px;
  font-weight: 500;
  color: $gray-500;
  &:first-child {
    line-height: 5px;
    padding: 4px 8px 4px 16px;
  }
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  background: $gray-50;
  border: none;
  min-height: 32px;
  // border-right: 1px solid $gray-200;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    background: $gray-100;
    color: $gray-900;
  }
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
:where(.css-dev-only-do-not-override-12eape7).ant-tabs-card.ant-tabs-top
  > div
  > .ant-tabs-nav
  .ant-tabs-tab-active {
  background: $white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  // border-top: 1px solid $gray-400;
  // border-right: 1px solid $gray-400;
  // border-left: 1px solid $gray-400;
  // border-bottom: 5px solid #white;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
:where(.css-dev-only-do-not-override-12eape7).ant-tabs-card.ant-tabs-top
  > div
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0px;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 32px;
  min-height: 32px;
  width: 32px; // Ensuring width is the same as min-height for a circle
  line-height: 24px; // Center the content vertically
  display: flex; // Use flexbox for centering
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
  margin-left: 4px;
  padding: 0;
  color: $gray-500;
  border-radius: 50%;
  background: transparent;
  border: none;
  cursor: pointer; // Change cursor to pointer to indicate it's clickable
  transition: background-color 0.3s ease-in-out;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: $primary;
  padding: 8px !important;
  background-color: $gray-100; // Semi-transparent circle effect on hover
}

.ant-tabs-dropdown {
  z-index: 2000 !important;
}

// .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
//     margin: 0 0 0 16px;
// }

// .ant-tabs-nav .ant-tabs-tab {
//   padding: 7px 2px 7px !important;
//   color: $gray-700;
//   &:first-child {
//     line-height: 5px;
//     padding-left: 0px !important;
//   }
//   &:last-child,
//   &:last-of-type {
//     margin-right: 0 !important;
//   }
// }

// .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
// .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
// .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
// .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
//   padding: 0px 0px 0px 0px !important;
// }

// // .kit-tabs,
// // .kit-tabs-bold,
// // .kit-tabs-pills {
// //   overflow: visible !important;

// //   .ant-tabs-top-bar {
// //     margin-bottom: 0;
// //     border-bottom: none;
// //     height: 100%;
// //   }
// //   .ant-tabs-nav-container,
// //   .ant-tabs-nav-wrap,
// //   .ant-tabs-nav-scroll {
// //     height: 100%;
// //     overflow: visible !important;
// //   }

// //   .ant-tabs-nav {
// //     height: 100%;

// //     &:before {
// //       bottom: -1px !important;
// //     }

// //     & > div {
// //       height: 100%;
// //     }

// //     .ant-tabs-ink-bar {
// //       height: 1px;
// //       bottom: -1px !important;

// //       &[style*='width: 0px;'] {
// //         width: 100% !important;
// //       }
// //     }
// //   }

// //   .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
// //     font-weight: 700;
// //   }

// //   .ant-tabs-tab-active {
// //     .nav-link {
// //       background-color: $primary;
// //       color: $white;
// //       font-weight: 700 !important;
// //       border-radius: 0.25rem;
// //     }
// //   }
// // }

// .ant-tabs-tab:hover {
//   color: $primary !important;
//   background-color: $gray-50 !important;
//   // padding: rem(1.8) rem(9);
// }

// .ant-tabs-tab-btn:hover {
//   background-color: $gray-50 !important;
//   border-radius: 5px;
//   // padding: rem(1.8) rem(9);
// }

// .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
//   background-color: $gray-50 !important;
//   border-radius: 5px;
//   padding: rem(1.8) rem(9);
// }

// .ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top >div>.ant-tabs-nav .ant-tabs-ink-bar {
//     width: '100%';
//     bottom: 0;
// }

// .kit-tabs-bold {
//   .ant-tabs-nav {
//     .ant-tabs-ink-bar {
//       height: 2 !important;
//       bottom: -1px !important;
//       border-radius: 7px !important;
//     }
//   }
// }

// .kit-tabs-pills {
//   .ant-tabs-ink-bar {
//     display: none !important;
//   }

//   .ant-tabs-nav {
//     .ant-tabs-tab {
//       padding-left: 0;
//       padding-right: 0;
//     }
//   }
// }

// .kit-tabs-bordered {
//   .ant-tabs-nav-list {
//     padding-left: rem(24);
//     padding-right: rem(24);
//   }

//   &.kit-tabs-bold {
//     .ant-tabs-top-bar {
//       border-bottom: 1px solid $border;
//     }
//   }
// }
