@import 'components/mixins.scss';

// main layout

.mobileMenu {
  :global(.ant-drawer-content),
  :global(.ant-drawer-wrapper-body) {
    overflow: visible !important;
    padding-bottom: 20px !important;
    pointer-events: all;
  }
  :global(.ant-drawer-body) {
    padding: 0 !important;
    pointer-events: all;
    height: 100%;
  }
  :global(.ant-layout-sider) {
    height: 100%;
  }
}

.handler {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 42px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #001529;
  border-radius: 0 4px 4px 0;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}

.handlerIcon {
  width: 14px;
  height: 2px;
  background: #fff;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    background: #fff;
    width: 100%;
    height: 2px;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }

  &:before {
    top: -5px;
  }

  &:after {
    top: 5px;
  }
}
