@import 'components/mixins.scss';
/////////////////////////////////////////////////////////////////////////////////////////

/*  "CORE" STYLES */

// @font-face {
//   font-family: 'MaisonNeue';
//   src: url('/fonts/MaisonNeue-Book.ttf') format('truetype');
//   font-weight: 400; /* Normal weight */
//   font-style: normal;
// }

// @font-face {
//   font-family: 'MaisonNeue';
//   src: url('/fonts/MaisonNeue-Medium.ttf') format('truetype');
//   font-weight: 500; /* Semi-bold weight */
//   font-style: medium;
// }

// @font-face {
//   font-family: 'MaisonNeue';
//   src: url('/fonts/MaisonNeue-Demi.ttf') format('truetype');
//   font-weight: 700; /* Semi-bold weight */
//   font-style: bold;
// }

// @font-face {
//   font-family: 'MaisonNeue';
//   src: url('/fonts/MaisonNeue-Bold.ttf') format('truetype');
//   font-weight: 700; /* Semi-bold weight */
//   font-style: bold;
// }

// @font-face {
//   font-family: 'AvenirNextLTPro';
//   src: url('/fonts/AvenirNext-Medium.ttf') format('truetype');
//   font-weight: 400; /* Normal weight */
//   font-style: normal;
// }

// @font-face {
//   font-family: 'AvenirNextLTPro';
//   src: url('/fonts/AvenirNext-DemiBold.ttf') format('truetype');
//   font-weight: 500; /* Semi-bold weight */
//   font-style: medium;
// }

// @font-face {
//   font-family: 'AvenirNextLTPro';
//   src: url('/fonts/AvenirNextLTPro.ttf') format('truetype');
//   font-weight: 700; /* Semi-bold weight */
//   font-style: bold;
// }

// @font-face {
//   font-family: 'ArcadiaDisplay';
//   src: url('/fonts/ArcadiaText-Variable.woff2') format('woff2-variations'),
//     url('/fonts/ArcadiaText-Variable.woff2') format('woff2'); /* Fallback */
//   font-weight: 100 900; /* Variable weight range */
//   font-stretch: 75% 125%; /* Optional: Adjust width variation */
//   font-style: normal;
// }

:root {
  --kit-color-primary: $primary;
  --kit-font-family: 'AvenirNextLTPro', 'Inter', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --kit-thermal-font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --kit-theme: $theme;
}

html {
  font-size: 14px;
  font-family: $base-font-family;
  // letter-spacing: rem(0.18);
  @media (max-width: $md-max-width) {
    font-size: 13px;
  }
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body {
  overscroll-behavior-y: none;
  font-size: 1rem;
  line-height: 1.4;
  font-family: $base-font-family;
  color: $text;
  background-color: $white;
  overflow-x: hidden;
  position: relative;
  font-variant: normal;
  font-feature-settings: normal;

  @media (max-width: $md-max-width) {
    font-size: 0.5rem;
  }
}

.h1,
h1 {
  font-size: 2.5rem;
}
.h2,
h2 {
  font-size: 2rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h4,
h4 {
  font-size: 1.5rem;
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.36rem;
}

a {
  text-decoration: none;
  color: $text;
  @include transition-color();

  &:hover,
  &:active,
  &:focus {
    color: $primary;
    text-decoration: none;
  }
}

a_link {
  text-decoration: none;
  color: $gray-500;
  cursor: pointer;
  @include transition-color();

  &:hover,
  &:active,
  &:focus {
    color: $gray-900;
    text-decoration: none;
  }
}

help_link {
  text-decoration: none;
  color: $gray-500;
  cursor: pointer;
  @include transition-color();

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}

a_copy_text {
  text-decoration: none;
  color: $gray-400;
  cursor: pointer;
  @include transition-color();

  &:hover,
  &:active,
  &:focus {
    color: $gray-700;
    text-decoration: none;
  }
}

a_copy_text_dark {
  text-decoration: none;
  color: $gray-200;
  cursor: pointer;
  @include transition-color();

  &:hover,
  &:active,
  &:focus {
    color: $gray-500;
    text-decoration: none;
  }
}

input {
  outline: none !important;
  font-family: $base-font-family;
  color: $gray-900;
}

button,
input {
  box-shadow: none !important;
  outline: none !important;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  appearance: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $gray-900;
}

svg {
  vertical-align: initial;
  overflow: auto;
}

.stick-bottom {
  position: sticky;
  bottom: 0;
}

.font-thermalPrint {
  font-family: 'Roboto Mono' !important;
}

.numbers {
  font-family: $base-font-family;
}

.badge-example {
  font-size: rem(14);
  text-transform: uppercase;
  margin-bottom: rem(15);
  background: gray-200;
  color: $black;
  display: inline-block;
  padding: rem(3) rem(6);
  border-radius: 4px;
}

.text-capitalize {
  text-transform: capitalize;
}

// // dark theme
// [data-kit-theme='dark'] {
//   body {
//     background: $dark-gray-5;
//     color: $dark-gray-1;
//   }

//   .badge-example {
//     background: $dark-gray-4;
//   }

//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     color: $dark-gray-1;
//   }

//   a {
//     color: $dark-gray-1;

//     &:hover,
//     &:active,
//     &:focus {
//       color: $primary;
//       text-decoration: none;
//     }
//   }
// }

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: 700 !important;
}

.ls-1 {
  // letter-spacing: rem(-0.27) !important;
}

.ls-2 {
  // letter-spacing: rem(-0.54) !important;
}

.center-text {
  text-align: center;
}

.pt-small {
  padding-top: rem(1.8) !important;
}

.lh-large {
  line-height: 1.5rem !important;
}

.lh-normal {
  line-height: 1rem !important;
}

// Badge styles
.badge {
  font-weight: normal;
  background: $white;
  color: $secondary;
  font-size: rem(9);
  letter-spacing: 1px;
  line-height: 0.72rem;
  text-transform: uppercase;
  padding: 0.27rem 0.45rem;
  vertical-align: middle;

  &.badge-default {
    background: $default;
    color: $white;
  }
  &.badge-primary {
    background: $primary;
    color: $white;
  }
  &.badge-free {
    background: transparent;
    border: 1px solid $primary;
    color: $primary;
  }
  &.badge-secondary {
    background: $secondary;
    color: $white;
  }
  &.badge-success {
    background: $success;
    color: $white;
  }
  &.badge-danger {
    background: $danger;
    color: $white;
  }
  &.badge-warning {
    background: $warning;
    color: $white;
  }
  &.badge-info {
    background: #ff5733;
    color: $white;
  }
  &.badge-pro {
    background: #ff5733 !important;
    color: $white;
  }
  &.badge-light {
    background: $light;
    color: $text;
  }
  &.badge-dark {
    background: $dark;
    color: $white;
  }
  &.badge-gold {
    background: #ffc043;
    color: $black;
  }
  &.badge-jet {
    background: #c822ce;
    color: $white;
  }
  &.badge-beta {
    background: transparent; //#2754FF;
    color: $beta-tag-color;
  }
  &.badge-rise {
    background: #0fa958;
    color: $white;
  }
  &.badge-gray {
    background: $gray-100;
    color: $gray-700;
  }
  &.badge-gray-light {
    background: $gray-75;
    color: $gray-900;
    font-weight: 500;
  }
  &.badge-transparent {
    background: transparent;
    border: none;
  }
}

.badge-large {
  border-radius: rem(9);
  font-size: rem(27);
  letter-spacing: 2px;
  line-height: rem(27);
  padding: 0.54rem 1.08rem;
}

.badge-medium {
  border-radius: rem(6);
  font-size: rem(14.4);
  letter-spacing: 2px;
  line-height: rem(9);
  padding: 0.54rem 1.08rem;
}

.badge-small {
  font-size: rem(12.6);
  line-height: 0.72rem;
  padding: 0.27rem 0.45rem;
}

.pos-absolute {
  position: absolute;
}

.banners {
  width: '100%';
  color: $white;
  text-align: 'center';
  padding: '5.4px';
  border-radius: '14.4px';
}

//Autocomplete custom styles

.auto-complete-input {
  margin-bottom: 2rem;
  padding: 0.3rem;
  background: transparent;
  border: 1px solid $gray-200;
  outline: none;
  width: 36rem;
  border-radius: 5px;
  appearance: none;
  color: $gray-900;
  // width: 100% !important;
}

.auto-complete-input::placeholder {
  color: $gray-300 !important;
  font-size: 0.96rem;
}

.auto-complete-input:focus,
.auto-complete-input:hover,
.auto-complete-input:active {
  border: 1px solid $primary;
}

.autofill-btn {
  background: $white;
  //margin-top: 0.9rem !important;
  border-radius: 0.45rem;
  // padding: 0.36rem 0.18px 0px 0.18px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.02), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);

  @media (max-width: 490px) {
    width: 100% !important;
  }
}

.auto-add-btn:hover {
  background-color: $gray-100;
}

.address-radio-btn {
  width: 390px !important;
}

// #style-9::-webkit-scrollbar-track {
//   background-color: none;
//   border-radius: 10px;
//   height: 2.7px !important;
// }
.custom-style-scrollbar {
  max-height: 400px;
  overflow-y: auto;
}

.custom-style-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-style-scrollbar::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  border-radius: 8px;
  min-height: 50px;
}

.custom-style-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 8px;
}

.custom-style-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

// #style-9::-webkit-scrollbar {
//   width: 0.45rem;
//   height: 0.27rem;
//   background-color: none;
// }

// #style-9::-webkit-scrollbar:hover {
//   width: 12px;
//   height: 8px;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 10px $gray-75;
//   border-radius: 2.7px;
// }

// #style-9::-webkit-scrollbar-thumb {
//   border-radius: 3.6rem;
//   background-color: $gray-100;
// }

.watermark {
  background-image: url('https://app.getswipe.in/resources/images/watermark.svg');
}

.doc-link-preview-top-bar {
  padding: 0 1.8rem 0 0.9rem;
  min-height: 64px;
  height: 64px;
  // background-color: $white;
  // width: '100%' !important;
  box-shadow: 0 1px $gray-100;
  // border-top: 1px solid $gray-100;
  // border-bottom: 1px solid $gray-400;
  color: $text;
  display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  // position: fixed;
  touch-action: none;

  @media (max-width: $sm-max-width) {
    padding: 0 rem(16);
  }
}

//multi email css

.show-description {
  display: none;
}

@media screen and (min-width: 1200px) {
  .show-description {
    display: block;
  }
}

.autocomplete-inputstyless {
  width: 100%;
  height: 32px !important;
  border: 1px solid $gray-100 !important;
  border-radius: 5px !important;
  padding-left: 13px !important;
  font-size: 14px !important;
  // border-color: none !important;
  background-color: $white;
  border-right-width: 1px !important;
  transition: all 0.2s linear;
  &:hover,
  &:focus,
  &:active {
    // border: 1px solid blue !important;
    // border: 1px solid $primary !important;
    // border-radius: 5px !important;
    // border-width: 1px !important;
    box-shadow: 0 0 0 2.7px $primary-light !important;
  }
}
.autocomplete-inputstyless::placeholder {
  color: $gray-300 !important;
}

.autocomplete-items {
  background: $white;
  cursor: pointer;
  padding: 0 10px;
  color: $black; // Default text color
  &:hover {
    background: $gray-100; // Change text color to black on hover
  }
}

.product-autocomplete > .ant-select-selector {
  border-radius: 0px 5px 5px 0px !important;
}
.product-autocomplete-select > .ant-select-selector {
  border-radius: 5px 0px 0px 5px !important;
}

.report-menu .ant-menu-submenu-title {
  padding-left: 0px !important;
}

.report-menu .ant-menu-inline > .ant-menu-item,
.report-menu .ant-menu-vertical > .ant-menu-item,
.report-menu .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.report-menu .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 27px;
  line-height: 18px;
}

.report-menu li .ant-menu-item {
  padding-left: 12px !important;
  height: 18px !important;
  line-height: 18px !important;
}

.report-menu li .ant-menu-item-selected {
  color: $primary !important;
}

.ant-menu-inline .ant-menu-item:after {
  position: absolute;
  line-height: 20px !important;
  top: 0;
  right: 0;
  bottom: 0;
  //border-right: 3px solid $primary !important;
}

.report-menu .ant-menu-sub {
  background-color: transparent !important;
}

.report-menu .ant-menu-light.ant-menu-root.ant-menu-inline,
.report-menu .ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: 1px solid $gray-100;
}

.report-menu .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover,
.report-menu .ant-menu-light > .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
  background-color: transparent !important;
  color: $gray-900;
}

.report-menu .ant-menu-light {
  background-color: transparent !important;
}
.no-submenu {
  padding-left: 2px !important;
}
// :where(.css-dev-only-do-not-override-1yrjnjy).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover, :where(.css-dev-only-do-not-override-1yrjnjy).ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
//     background-color: rgba(29, 29, 31, 0.06);
// }

.settings-menu .ant-menu .ant-menu-item {
  padding-left: 0px !important;
  height: 24px !important;
}
.settings-menu .ant-menu .read-only-menu-item {
  margin-top: 15px;
}

// .settings-menu .ant-menu-light.ant-menu-root.ant-menu-inline,
// .settings-menu .ant-menu-light.ant-menu-root.ant-menu-vertical {
//   border-inline-end: 1px solid $gray-100;
// }

// Rich text editor

.RichEditor-root {
  background: $white;
  border: 1px solid $gray-100;
  font-family: 'Inter', serif;
  font-size: 14px;
  border-radius: 8px;
  padding: 9px 18px;
}

.RichEditor-editor {
  border-top: 1px solid $gray-200;
  cursor: text;
  font-size: 14px;
  margin-top: 0px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 64px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid $gray-100;
  color: $gray-500;
  font-family: 'Lato', 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 14px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 2.7px;
  user-select: none;
}

.RichEditor-styleButton {
  color: $gray-500;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: $primary;
}

.cursor-pointer {
  cursor: pointer !important;
}

.custom-form-label {
  font-weight: 500;
  font-size: 13px;
  color: $gray-500;
  margin: 0rem 0.24rem 0.3rem;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.arrow-transition i,
.arrow-transition-left i {
  transition: transform 0.5s ease;
}

.arrow-transition:hover i {
  transform: translateX(5px);
}
.arrow-transition-left:hover i {
  transform: translateX(-5px);
}

.expand-width {
  width: 10rem;
  transition: width 0.5s ease;
}

.expand-width:hover {
  width: 23.5rem;
}

.help-btn {
  background-color: #25d366;
  color: $white;
  border: none;
  border-radius: 40px;
  font-weight: bold;
  padding: 0px 15px;
  display: flex !important;
  align-items: center !important;
}

.pl-11 {
  padding-left: 11px !important;
}

@media (max-width: 768px) {
  .widthres {
    width: 120px !important; // override initial width to 100%
  }
}

@media (max-width: 768px) {
  .my-content {
    .ant-affix {
      display: none;
    }
  }
}

.card-no-padding > .ant-card-body {
  padding: 0px !important;
}

.my-select:hover .ant-select-item-option.auto-add-btn {
  background-color: $white !important;
}

.responsive-select .ant-select-selector {
  max-width: 144px !important;
}

@media screen and (max-width: 480px) {
  .responsive-select .ant-select-selector {
    max-width: 100px !important;
  }
}

.ant-color-picker-trigger > .ant-color-picker-color-block {
  width: 70px !important;
  height: 70px !important;
}

.ant-color-picker-trigger:hover {
  border-color: $gray-100 !important;
}

.arrow i {
  transition: transform 0.5s ease;
  visibility: hidden;
}

.arrow:hover i {
  transform: translateX(5px);
  visibility: visible;
}
.ant-tag-checkable-checked {
  background-color: $primary !important;
}
.ant-tag-checkable-checked > span {
  color: $white !important;
  font-weight: 800;
}

.ant-table-expanded-row-fixed {
  width: auto !important;
}
.company_name_text {
  color: $black;
  $white-space: nowrap;
  line-height: 1.26 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.bulk-edit-table {
  .ant-table-cell {
    a {
      padding-left: 17px !important;
    }
  }
}

.custom-collapse {
  .ant-collapse-header-text {
    padding-top: 7px !important;
  }
  .ant-collapse-content {
    transition: height 0.5s ease-in-out !important;
  }
  .ant-collapse-content-box {
    padding-left: 50px !important;
  }
}

.mention-input {
  border: 1px solid $gray-100 !important;
  color: $black;
  border-radius: 5px !important;
  min-height: 32px;
  &:focus {
    border: 1px solid $gray-100 !important;
    border-radius: 5px !important;
  }
  &:active {
    border: 1px solid $gray-100 !important;
    border-radius: 5px !important;
  }
}
.mention-input__control {
  textarea {
    border: 1px solid transparent !important;
    &:active,
    &:hover,
    &:focus {
      border-color: #4f78ff !important;
      outline: none !important;
      box-shadow: 0 0 0 2px rgba(5, 88, 255, 0.06);
      border-radius: 4px !important;
    }
  }
}

.mention-input__control textarea {
  color: $black;
}

.mention-input__suggestions {
  background-color: $white !important;
  border-radius: 10px !important;
}

.mention-input__highlighter {
  display: none !important;
}

// .mention-input__highlighter {
//   border: none !important;
//   height: 100%;
//   &:active,
//   &:hover,
//   &:focus {
//     border: 1px solid white !important;
//     border-color: #4f78ff;
//   }
// }
// // .mention-input__input {
// //   border: none !important;
// //   border-radius: 5px;

// //   &:hover {
// //     box-shadow: 0 0 0 2.7px #dfe5ff !important;
// //     border: none !important;
// //   }
// //   &:focus {
// //     box-shadow: 0 0 0 2.7px #dfe5ff !important;
// //     border: none !important;
// //   }
// // }

// :where(.css-dev-only-do-not-override-13z29z3).ant-input:focus,
// :where(.css-dev-only-do-not-override-13z29z3).ant-input-focused {
//   border-color: #4f78ff;
//   box-shadow: 0 0 0 2px rgba(5, 88, 255, 0.06);
//   border-inline-end-width: 1px;
//   outline: 0;
// }
.price-card {
  .ant-card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// Image warpper
.toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: $white;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}

// // beacon
// #bkn-pallete-tailwind :is(.bkn-text-\[\#313545\]) {
//     --tw-text-opacity: 1;
//     color: $gray-900 !important;
// }

// #bkn-pallete-tailwind :is(.bkn-text-opacity-75) {
//     --tw-text-opacity: 1 !important;
// }

// #bkn-pallete-tailwind :is(.bkn-text-\[\#31354566\]) {
//     color: $gray-500 !important;
// }

// #bkn-pallete-tailwind :is(.bkn-text-\[\#313545CC\]) {
//     color: $gray-900 !important;
// }

#bkn-pallete-tailwind :is(.bkn-py-\[12px\]) {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

#bkn-pallete-tailwind :is(.bkn-mt-2) {
  margin-top: 0.1em !important;

  #bkn-pallete-tailwind :is(.bkn-text-\[\#313545\]) {
    --tw-text-opacity: 1;
    color: $gray-900 !important;
  }

  #bkn-pallete-tailwind :is(.bkn-text-\[\#31354566\]) {
    color: $gray-900 !important;
  }

  .absolute {
    position: absolute;
  }
}

#bkn-pallete-tailwind :is(.bkn-rounded-2xl) {
  border-radius: 0.5rem !important;
}

.edit-popover .popover-child {
  display: none;
}

.edit-popover:hover .popover-child {
  display: inline;
}

// .warehouses .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
// .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
//   padding: 5px 0px !important;
// }

.ant-tooltip {
  max-width: 400px !important;
}
.btn-secondary-font-normal > span {
  font-weight: 500 !important;
}
.ant-menu-item:hover {
  background-color: transparent !important;
}

/* Upward collapse classes */
/* This class should be applied to the parent container of the Collapse component */
.upward-collapse-wrapper {
  position: relative; /* Create a positioning context */
  height: 64px; /* Set to the desired height, or use min-height */
}

/* This class should be applied to the Collapse component itself */
.custom-upward-collapse {
  position: absolute;
  bottom: 0; /* Position the collapse at the bottom */
  width: 100%; /* Set the desired width */
}

/* This class should be applied to the Panel component of the Collapse */
.custom-collapse-panel {
  position: relative;
  bottom: -100%; /* Initially, position the content off-screen */
}

/* Adjust the positioning when the Collapse Panel is active */
.custom-collapse-panel.ant-collapse-item-active {
  bottom: 0; /* Bring the content upwards on activation */
}

.ant-alert {
  align-items: center !important;
}

.pos-product-autocomplete .ant-select-selector {
  height: 40px !important;
}

.blink_me {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.address-line {
  display: block;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines before truncation */
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Number of lines before truncation */
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-flex-grow {
  flex-basis: 50%;
  max-width: 50%;
}

.edit-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-button-wrapper {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: white; /* Ensure the background matches the menu background */
  padding: 4px 0; /* Adjust padding as needed */
  text-align: center;
  border-top: 1px solid #f0f0f0; /* Optional: Add a top border to separate the button */
}

// AG GRID CSS

.ag-no-select {
  --ag-range-selection-border-style: 0 !important;
  --ag-range-selection-background-color: transparent !important;

  .ag-fill-handle {
    display: none;
  }
}

.visible-on-ag-row-hover {
  display: none;
}

.ag-row-hover {
  .visible-on-ag-row-hover {
    display: block;
  }

  .hidden-on-ag-row-hover {
    display: none;
  }
}

.sigCanvas {
  border: 0.05rem solid #e8e8ed;
}

.shortcut-tooltip {
  position: fixed;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 10000000;
  white-space: nowrap;
  overflow: hidden;
  visibility: hidden;
  animation: showTooltip 0.5s forwards;
}

@keyframes showTooltip {
  to {
    visibility: visible;
  }
}

.no-border {
  .RichEditor-editor {
    border: 0px !important;
  }
}

.table-blurred-row {
  filter: blur(3px); /* Apply blur effect */
  opacity: 0.6; /* Reduce opacity */
  transition: all 0.3s ease-in-out;
}
.disabled-row {
  pointer-events: none; /* Disable clicks */
  user-select: none; /* Prevent text selection */
  cursor: not-allowed; /* Show a disabled cursor */
}

.element-style {
  .ant-select-selector {
    font-size: 16px !important;
    font-weight: 900 !important;
  }
}
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.blinking-animation {
  display: inline-block;
  animation: pulse 1.5s infinite ease-in-out;
}
