// Import mixins
@import 'components/mixins.scss';

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $gray-25 !important;
  border-radius: 3px;
  font-weight: 700 !important;
  color: $black !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline,
.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: none;
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-menu {
    &-item {
      &-group-title {
        color: $action;
      }
      &-disabled {
        color: $dark-gray-3 !important;
      }
    }
  }
}
