// Import mixins
@import 'components/mixins.scss';


//timeline last

.ant-timeline-item-last {
  padding-bottom: 0px !important;
}

[data-kit-theme="default"] .ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 18px !important;
}

