@import 'components/mixins.scss';

.FeatureBar {
  padding: 0 1.8rem 0 0.9rem;
  min-height: 64px;
  height: 64px;
  background-color: $white;
  // width: '100%' !important;
  box-shadow: 0 1px $gray-100;
  // border-top: 1px solid $gray-100;
  // border-bottom: 1px solid $gray-400;
  color: $text;
  display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  // position: fixed;
  touch-action: none;

  @media (max-width: $sm-max-width) {
    padding: 0 rem(16);
  }
}

.name {
  color: $black;
  white-space: nowrap;
  line-height: 1.26 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

// dark theme
[data-kit-theme='dark'] {
  .topbar {
    background: $gray-900;
    color: $dark-gray-1;
    border-bottom: 1px solid $dark-gray-4;
  }
}
