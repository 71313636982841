// Import mixins
@import 'components/mixins.scss';

.ant-notification {
  max-width: calc(100vw - 48px);
}

// .ant-message {
//   -webkit-box-sizing: border-box;
//           box-sizing: border-box;
//   margin: 0;
//   padding: 0;
//   color: rgba(0, 0, 0, 0.85);
//   font-size: 14px;
//   font-variant: tabular-nums;
//   line-height: 1.5715;
//   list-style: none;
//   -webkit-font-feature-settings: 'tnum';
//           font-feature-settings: 'tnum';
//   position: fixed;
//   top: 8px;
//   left: 0;
//   z-index: 1010;
//   width: 100%;
//   pointer-events: none;
// }
// .ant-message-notice {
//   padding: 8px;
//   text-align: center;
// }
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 0 1px rgba(136, 152, 170, 0.1), 0 15px 35px 0 rgba(49, 49, 93, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 0 0 1px rgba(136, 152, 170, 0.1), 0 15px 35px 0 rgba(49, 49, 93, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  pointer-events: all;
}


////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-notification {
    &-notice-message {
      color: $white;
    }
    &-close-icon {
      svg {
        fill: $white;
      }
    }
  }
}
