@font-face {
    font-family: Buffalo;
    src: url('../../../fonts/signature/Buffalo.otf') format('opentype');
}

@font-face {
    font-family: Pacifico;
    src: url('../../../fonts/signature/Pacifico.ttf') format('opentype');
}

@font-face {
    font-family: Creattion;
    src: url('../../../fonts/signature/Creattion.otf') format('opentype');
}

@font-face {
    font-family: Scriptin;
    src: url('../../../fonts/signature/Scriptin.ttf') format('opentype');
}

@font-face {
    font-family: MrDeHaviland;
    src: url('../../../fonts/signature/MrDeHaviland.ttf') format('opentype');
}

@font-face {
    font-family: MonsieurLaDoulaise;
    src: url('../../../fonts/signature/MonsieurLaDoulaise.ttf') format('opentype');
}

@font-face {
    font-family: MrsSaintDelafield;
    src: url('../../../fonts/signature/MrsSaintDelafield.ttf') format('opentype');
}

@font-face {
    font-family: TheQueenthine;
    src: url('../../../fonts/signature/TheQueenthine.ttf') format('opentype');
}

@font-face {
    font-family: Julietta-Messie;
    src: url('../../../fonts/signature/Julietta-Messie.otf') format('opentype');
}

.signatureFont {
    font-size: 3rem;
    padding: 1rem 1rem 2rem;
    transform: rotate(-5deg);
}
